import produce from 'immer';
import { type Action, type Reducer } from 'redux';

import { actionTypes } from './companySettings.actions';
import { initialCompanyProfile, type DispatchActions, ICompanyData, initialPendoData } from './companySettings.types';

const unloadedState: ICompanyData = {
	companyId: 0,
	isLoading: false,
	isSsrLogo: true,
	logoPath: '',
	companyProfile: initialCompanyProfile,
	companyUploadProfileLink: '',
	pendoData: initialPendoData,
};

export const reducer: Reducer<ICompanyData> = produce(
	(draftState: ICompanyData = unloadedState, incomingAction: Action) => {
		const action = incomingAction as DispatchActions;
		switch (action.type) {
			case actionTypes.RECEIVE_COMPANY_ID:
				const companyData = { ...draftState };
				companyData.companyId = action.companyId;
				draftState = companyData;
				break;
			case actionTypes.RECEIVE_COMPANY_LOGO:
				const initialData = { ...draftState };
				initialData.isSsrLogo = action.isSsrLogo;
				initialData.logoPath = action.logoPath;
				draftState = initialData;
				break;
			case actionTypes.RECEIVE_COMPANY_PROFILE:
				const tempCompanyData = { ...draftState };
				tempCompanyData.companyProfile = action.companyProfile;
				draftState = tempCompanyData;
				break;
			case actionTypes.REQUEST_COMPANY_PROFILE:
				return {
					...draftState,
					isLoading: action.isLoading
				};
			case actionTypes.UPDATE_COMPANY_SIGNATURE:
				return {
					...draftState,
					companyUploadProfileLink: action.sasUrl
				};
			case actionTypes.RECEIVE_PENDO_DATA:
				const tempPendoData = { ...draftState };
				tempPendoData.pendoData = action.pendoData;
				draftState = tempPendoData;
				break;

			default:
				return draftState;
		}
		return draftState;
	},
);
