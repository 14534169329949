import axios, { AxiosResponse } from 'axios';
import { API_BASE_URL } from 'src/helper/Constants';
import { ISubDomainExistsResponse, SUBDOMAIN_EXISTS_ENUM } from './ClientHub.model';

export const checksubDomainExists = (
	subDomainName: string,
	callback: (subDomainExistsResponse: ISubDomainExistsResponse) => void,
) => {
	const subDomainExistsResponse: ISubDomainExistsResponse = {
		value: SUBDOMAIN_EXISTS_ENUM.NOTEXISTS,
		error: false,
	};
	axios
		.post(`${API_BASE_URL}/api/ute-setting/subdomain/is-exists`, JSON.stringify(subDomainName), {
			headers: { 'Content-Type': 'application/json-patch+json' },
		})
		.then((response: AxiosResponse<boolean>) => {
			const { data } = response;
			if (data) {
				subDomainExistsResponse.value = SUBDOMAIN_EXISTS_ENUM.EXISTS;
			} else {
				subDomainExistsResponse.value = SUBDOMAIN_EXISTS_ENUM.NOTEXISTS;
			}
			callback(subDomainExistsResponse);
		})
		.catch((error) => {
			if (error.response?.status === 400) {
				subDomainExistsResponse.value = SUBDOMAIN_EXISTS_ENUM.INVALID;
			} else {
				subDomainExistsResponse.error = true;
			}
			callback(subDomainExistsResponse);
		});
};

export const updateSubDomain = (subDomainName: string, callback: (updateResponseSuccessful: boolean) => void) => {
	axios
		.post(`${API_BASE_URL}/api/ute-setting/update-subdomain`, JSON.stringify(subDomainName), {
			headers: { 'Content-Type': 'application/json-patch+json' },
		})
		.then(() => {
			callback(true);
		})
		.catch(() => {
			callback(false);
		});
};
