type EditIconProps = {
	color?: string;
};

function EditIcon({ color }: EditIconProps) {
	return (
		<svg
			width='17'
			height='17'
			viewBox='0 0 17 17'
			fill='none'
			xmlns='http://www.w3.org/2000/svg'>
			<g clip-path='url(#clip0_6256_1981)'>
				<rect
					width='16'
					height='16'
					transform='translate(0.5 0.5)'
					fill='white'
					fill-opacity='0.01'
				/>
				<g clip-path='url(#clip1_6256_1981)'>
					<path
						d='M12.6465 0.64547C12.8417 0.450208 13.1583 0.450208 13.3536 0.64547L16.3536 3.64547C16.5488 3.84073 16.5488 4.15731 16.3536 4.35258L6.35357 14.3526C6.30569 14.4005 6.24858 14.4381 6.18571 14.4633L1.18571 16.4633C1 16.5375 0.787892 16.494 0.646461 16.3526C0.50503 16.2111 0.461493 15.999 0.535776 15.8133L2.53578 10.8133C2.56092 10.7505 2.59858 10.6934 2.64646 10.6455L12.6465 0.64547ZM11.7071 2.99902L14 5.29192L15.2929 3.99902L13 1.70613L11.7071 2.99902ZM13.2929 5.99902L11 3.70613L4.50001 10.2061V10.499H5.00001C5.27616 10.499 5.50001 10.7229 5.50001 10.999V11.499H6.00001C6.27616 11.499 6.50001 11.7229 6.50001 11.999V12.499H6.79291L13.2929 5.99902ZM3.53167 11.1745L3.42614 11.28L1.89754 15.1015L5.71903 13.5729L5.82456 13.4674C5.63496 13.3963 5.50001 13.2134 5.50001 12.999V12.499H5.00001C4.72387 12.499 4.50001 12.2752 4.50001 11.999V11.499H4.00001C3.78561 11.499 3.60272 11.3641 3.53167 11.1745Z'
						fill={color ?? '#05386B'}
					/>
				</g>
			</g>
			<defs>
				<clipPath id='clip0_6256_1981'>
					<rect
						width='16'
						height='16'
						fill='white'
						transform='translate(0.5 0.5)'
					/>
				</clipPath>
				<clipPath id='clip1_6256_1981'>
					<rect
						width='16'
						height='16'
						fill='white'
						transform='translate(0.5 0.5)'
					/>
				</clipPath>
			</defs>
		</svg>
	);
}

export default EditIcon;
