import { Reducer } from 'redux';
import axios, { AxiosResponse } from 'axios';

import { API_BASE_URL } from 'src/helper/Constants';
import { AppThunkAction } from 'src/store/index';
import { CCHSettingState, ConnectionDetail, KnownAction, OAuthDetail, EFileSettings } from '../CCH/CCHSetting.model';
import { actionTypes as notificationTypes } from 'src/pages/common/notification/notification.types';
import { StatusType } from 'src/pages/common/notification/notification.reducer';
import { actionTypes } from './CCHSetting.action';
import { logger } from 'src/oidcClient/authProvider';

export const actionCreators = {
    getEFileSettings: (): AppThunkAction<KnownAction> => (dispatch, getState) => {
        axios.get(API_BASE_URL + '/api/efile/settings')
            .then((response: AxiosResponse<EFileSettings>) => {
                const { data } = response;
                dispatch({ type: actionTypes.RESPONSE_CCH_AllowEfile, eFileSettings: data });
            })
            .catch((error) => {
                const statusMessage: any = error.statusText?.message ?? error.statusText;
                if (typeof (statusMessage) === "string") {
                    dispatch({ type: notificationTypes.NOTIFICATION, statusMessage: statusMessage, statusType: StatusType.Error })
                }
            });
    },
    updateEfileSetting: (allowEfile: boolean, callback?: () => void): AppThunkAction<KnownAction> => (dispatch, getstate) => {
        axios.post(API_BASE_URL + '/api/efile/setting',
            {
                "isEfileEnabled" : allowEfile,                   
            }
        )
        .then((response: AxiosResponse<boolean>) => {
            const { data } = response;
            dispatch({ type: actionTypes.UPDATE_CCH_AllowEfile, eFileSettingsEnabled: data });
            if(callback){
                callback();
            } 
        })
        .catch(function (error) {
            logger && logger.trackWarning('Error while saveMFAIPAddress', { 'Error': error?.response?.data});
            const statusMessage: any = error.statusText;
            if (typeof (statusMessage) === "string") {
                dispatch({ type: notificationTypes.NOTIFICATION, statusMessage: statusMessage, statusType: StatusType.Error });
            }
        });
    },
    getOAuthDetails: (): AppThunkAction<KnownAction> => (dispatch, getState) =>{
        axios.get(API_BASE_URL + '/api/cchauth/config')
            .then((response: AxiosResponse<OAuthDetail>) => {
                const { data } = response;
                dispatch({ type: actionTypes.RESPONSE_OAUTH_DETAILS, data: data });
            })
            .catch((error) => {
                const statusMessage: any = error.statusText?.message ?? error.statusText;
                if (typeof (statusMessage) === "string") {
                    dispatch({ type: notificationTypes.NOTIFICATION, statusMessage: statusMessage, statusType: StatusType.Error })
                }
            });    
    },    
    getConnectionStatus: (callback? : () => void) : AppThunkAction<KnownAction> => (dispatch, getState) =>{
        axios.get(API_BASE_URL + '/api/cchauth/status')
            .then((response: AxiosResponse<ConnectionDetail>) => {
                const { data } = response;
                dispatch({ type: actionTypes.RESPONSE_CCH_CONNECTIONSTATUS, data: data });
                if(callback) callback();
            })
            .catch((error) => {
                const statusMessage: any = error.statusText?.message ?? error.statusText;
                if (typeof (statusMessage) === "string") {
                    dispatch({ type: notificationTypes.NOTIFICATION, statusMessage: statusMessage, statusType: StatusType.Error })
                }
            });
    },
    disconnect: (callback? : () => void) : AppThunkAction<KnownAction> => (dispatch, getState) =>{
        axios.get(API_BASE_URL + '/api/cchauth/disconnect')
            .then((response: AxiosResponse<boolean>) => {
                const { data } = response;
                dispatch({ type: actionTypes.RESPONSE_CCH_DISCONNECT, data : { status: false, userName: '' } });
                if(callback) callback();
            })
            .catch((error) => {
                const statusMessage: any = error.statusText?.message ?? error.statusText;
                if (typeof (statusMessage) === "string") {
                    dispatch({ type: notificationTypes.NOTIFICATION, statusMessage: statusMessage, statusType: StatusType.Error })
                }
            });
    },
    acessToken: (authCode: string, callback?: () => void) : AppThunkAction<KnownAction> => (dispatch, getState) => {
        axios.post(API_BASE_URL + '/api/cchauth/token',
            {
                "authCode" : authCode,                   
            }
        )
        .then((response: AxiosResponse) => {
            const { data } = response;
            if(callback) callback();
        })
        .catch(function (error) {
            logger && logger.trackWarning('Error while saveMFAIPAddress', { 'Error': error?.response?.data});
            const statusMessage: any = error.statusText;
            if (typeof (statusMessage) === "string") {
                dispatch({ type: notificationTypes.NOTIFICATION, statusMessage: statusMessage, statusType: StatusType.Error });
            }
        });
    }
}

const initialState : CCHSettingState = {
    oAuthDetail : {
        clientId: '',
        scope: '',
        redirectUri: '',
        authorizeUri : ''
    },
    connectionDetail : {
        userName:'',
        status:false
    },
    eFileSettings: {
        eFileFeatureEnabled: false,
        eFileSettingsEnabled: false
    }
}

export const reducer: Reducer<CCHSettingState> = (state = initialState, incomingAction) => {
    const action = incomingAction as KnownAction;
    switch (action.type) {
		case actionTypes.RESPONSE_OAUTH_DETAILS:
			return {
				...state,
				oAuthDetail: action.data
			} as CCHSettingState;
        case actionTypes.RESPONSE_CCH_DISCONNECT:
			return {
				...state,
				connectionDetail: action.data
			} as CCHSettingState;
		case actionTypes.RESPONSE_CCH_CONNECTIONSTATUS:
            return {
				...state,
				connectionDetail: action.data
			} as CCHSettingState;
        case actionTypes.RESPONSE_CCH_AllowEfile:
            return {
                ...state,
                eFileSettings: action.eFileSettings
            } as CCHSettingState;
        case actionTypes.UPDATE_CCH_AllowEfile:
            return {
                ...state,
                eFileSettings: { 
                    ...state.eFileSettings,
                    eFileSettingsEnabled: action.eFileSettingsEnabled 
                }
            } as CCHSettingState;  
	}
	return state;
};