import { connect } from 'react-redux';

import { type ApplicationState } from '../../store/index';
import GeneralSettings from './index';
import * as TaxSoftwareSettingStore from 'src/modules/generalSettings/TaxSoftwares/TaxSoftwareSetting.store';
import * as ClientManagementSettingStore from 'src/modules/generalSettings/ClientManagement/ClientManagementSetting.store';
import * as UTESettingStore from '../../modules/generalSettings/ClientHub/ClientHub.store';
import * as OverlayLoaderStore from '../common/overlayLoader/OverlayLoader.store';
import * as NotificationStore from '../common/notification/notification.reducer';
import * as CCHSettingStore from '../../modules/generalSettings/CCH/CCHSettings.store';

export default connect(
	(state: ApplicationState) => ({
		companyProfile: state.companyData.companyProfile,
		overlayLoader: state.overlayLoader,
		uteSetting: state.uteSetting,
		cchSettings: state.cchSettings
	}),
	{
		...TaxSoftwareSettingStore.actionCreators,
		...ClientManagementSettingStore.actionCreators,
		...UTESettingStore.actionCreators,
		...OverlayLoaderStore.actionCreators,
		...NotificationStore.actionCreators,
		...CCHSettingStore.actionCreators
	},
)(GeneralSettings as React.FC);
