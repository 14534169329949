import React from 'react';
import { Modal } from 'react-bootstrap';
import styles from './update-url-modal.module.scss';

type UpdateURLModalProps = {
	subdomain: string;
	hide: () => void;
	handleConfirmSubDomain: () => void;
};

const UpdateURLModal = ({ subdomain, hide, handleConfirmSubDomain }: UpdateURLModalProps) => {
	return (
		<div className={styles.updateURLConfirmation}>
			<Modal.Body>
				<p>
					Are you sure you would like to update the URL to
					<br />
					<span>www.{subdomain}.ssportal.com?</span>
				</p>
			</Modal.Body>
			<Modal.Footer>
				<button
					data-test-auto='b061a225-108c-4af9-b1da-ce3438d68cd1'
					className='noButton'
					onClick={hide}>
					No
				</button>
				<button
					data-test-auto='02b13db7-1419-4486-b51d-c38b0cc3be8b'
					className='yesButton'
					onClick={handleConfirmSubDomain}>
					Yes
				</button>
			</Modal.Footer>
		</div>
	);
};

export default UpdateURLModal;
