import React, { useState } from 'react';
import styles from './one-hub-url.module.scss';
import EditIcon from 'src/components/svg/EditIcon';

type OneHubUrlProps = {
	subDomain: string;
	onSubDomainChange: (subDomain: string) => void;
	onUpdateSubDomain: () => void;
	isUpdateDisabled: boolean;
	isEditClicked: boolean;
	onEditClicked: (isEditClicked: boolean) => void;
	urlError: string;
};

const OneHubUrl = ({
	subDomain,
	onSubDomainChange,
	onUpdateSubDomain,
	isUpdateDisabled,
	isEditClicked,
	onEditClicked,
	urlError,
}: OneHubUrlProps) => {
	return (
		<section className={styles.oneHubUrlSection}>
			<h3 className={styles.header}>Client Portal URL</h3>
			<div className={styles.urlContainer}>
				<div className={`${styles.inputContainer} ${urlError ? styles.errorBorder1 : ''}`}>
					<span className={styles.https}>https://</span>
					<input
						type='text'
						name='urlInput'
						id='urlInput'
						className={`${styles.urlInput} ${urlError ? styles.errorBorder2 : ''}`}
						maxLength={100}
						disabled={!isEditClicked}
						value={subDomain}
						onChange={(e) => {
							onSubDomainChange(e.target.value);
						}}
					/>
					<span className={styles.domain}>.ssportal.com</span>
				</div>
				<div className={styles.buttonContainer}>
					{!isEditClicked ? (
						<div className={styles.editContainer}>
							<button
								data-test-auto='dd4d5441-bec9-467d-84d0-0b8c85b504c2'
								className={styles.editButton}
								onClick={() => {
									onEditClicked(true);
								}}>
								<EditIcon color='#0973BA' />
								Edit
							</button>
						</div>
					) : (
						<div className={styles.updateContainer}>
							<button
								data-test-auto='acff986a-8cef-4e27-82fe-63d3fdbe4923'
								className={styles.updateButton}
								onClick={onUpdateSubDomain}
								disabled={isUpdateDisabled}>
								Update
							</button>
							<button
								data-test-auto='31bd5e56-66bc-45c6-a684-e4a3f40e681f'
								className={styles.cancelButton}
								onClick={() => {
									onEditClicked(false);
								}}>
								Cancel
							</button>
						</div>
					)}
				</div>
			</div>
			{isEditClicked && (
				<>
					{urlError && <div className={styles.urlError}>{urlError}</div>}
					<div className={styles.urlNamingCriteria}>
						<h5>URL Naming Criteria:</h5>
						<ul>
							<li>Letters and numbers</li>
							<li>Special characters allowed: -, _</li>
						</ul>
					</div>
				</>
			)}
		</section>
	);
};

export default OneHubUrl;
