import React, { FC, useEffect, useState } from 'react';
import styles from './CCHSetting.module.scss';
import ButtonWithTextIcon from 'src/components/common/button-with-text-icon';
import DisconnectModal from './DisconnectModal';
import SuiteModal from 'src/components/common/SuiteModal';
import { CCHSettingState } from './CCHSetting.model';
import { Button } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { ApplicationState } from '../../../store';
import { AppNotifier as VenusNotifier } from '../../../helper/AppNotifier';
import { ImportCCHAxcess } from '../CCH/Constants';
import { actionTypes as notificationTypes } from 'src/pages/common/notification/notification.types';
import { StatusType } from 'src/pages/common/notification/notification.reducer';
import { actionTypes as loaderTypes } from 'src/pages/common/overlayLoader/OverlayLoader.actions';

interface CCHSettingProps {
	cchSettings: CCHSettingState
    onDisconnect(callback? : () => void):void;
	getOAuthDetails(): void;
	getConnectionStatus(callback? : () => void): void;
	getAccessToken(authCode: string, callback? : () => void): void;
	getEFileSettings(): void;
	updateEfileSetting(allowEfile:boolean, callback? : () => void): void;
}

export const CCHSetting: React.FC<CCHSettingProps> = (props: CCHSettingProps) => {
    const [showDisconnectAlert, setShowDisconnectAlert] = useState(false);
	const eFileAllowed = useSelector((state: ApplicationState)=> state.cchSettings.eFileSettings.eFileSettingsEnabled);
	const [isEfileAllowed,setIsEfileAllowed] = useState(false); 
	const dispatch = useDispatch();
	useEffect(() => {
		props.getConnectionStatus();
		exchangeAuthCodeForAccessToken();
        props.getOAuthDetails();
		props.getEFileSettings();
	}, []);

	const onLogin = () => {
        props.getConnectionStatus(() => {
            const isLoggedIn = props.cchSettings.connectionDetail.status;
            if (isLoggedIn) {
                VenusNotifier.Warning(ImportCCHAxcess.loginAlertText); 
            } else {
                const oAuthDetail = props.cchSettings.oAuthDetail;
                const url = `${oAuthDetail.authorizeUri}?response_type=code&client_id=${oAuthDetail.clientId}&redirect_uri=${oAuthDetail.redirectUri}&scope=${oAuthDetail.scope}&prompt=login`;
                //window.location.href was updated to useHistory to fix synk vulnerability issue
                //but useHistory was not redirecting to external url , hence added window.location.assign
                window.location.assign(url);
            }
        });
    };

	const exchangeAuthCodeForAccessToken = () => {
        const href = window.location.href;
        if (href.includes("?code=")) {
            const url = new URL(href);
            const params = url.searchParams;
            let authcode = params.get("code");
            if (authcode) {
				props.getAccessToken(authcode, () => {
                    props.getConnectionStatus();
                    const newUrl = window.location.href.split("?")[0];
                    window.history.replaceState({}, document.title, newUrl);
                });
            }
        }
    }

    const showDisconnectAlertPopUp = () => {
        setShowDisconnectAlert(true);
    }
    const onDisconnectAlertYes = () => {
		dispatch({
			type: loaderTypes.LOADING,
			show: true,
			text: 'Logging out....',
		});
		props.onDisconnect(() => {
			dispatch({
				type: loaderTypes.LOADING,
				show: false,
				text: '',
			});
			dispatch({
				type: notificationTypes.NOTIFICATION,
				statusType: StatusType.Success,
				statusMessage: ImportCCHAxcess.disconnectSuccessText,
			});
		}); 		
		setShowDisconnectAlert(false);
	};

	const onDisconnectAlertNo = () => {
		setShowDisconnectAlert(false);
	};
	
	const handleToggleSwitchChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		dispatch({
			type: loaderTypes.LOADING,
			show: true,
			text: 'Updating E-file setting...',
		});
		const switched = event.target.checked;
		props.updateEfileSetting(switched, () => {
			dispatch({
				type: loaderTypes.LOADING,
				show: false,
				text: '',
			});
			dispatch({
				type: notificationTypes.NOTIFICATION,
				statusType: StatusType.Success,
				statusMessage: switched === true ? ImportCCHAxcess.allowEfileEnabledText : ImportCCHAxcess.allowEfileDisabledText,
			});
			setIsEfileAllowed(switched);
		}); 
	};

	return(
        <article className='suitePageSection'>
            <h3 className='suitePageSectionHeader'>CCH Integration</h3>
			<h3 className='suitePageSectionSubHeader'>Login</h3>
            <p className='suitePageSectionDescription'>
                Log in with a CCH Axcess account to import and e-file from SafeSend.
                <br />
                Only one login can be used at a time.
            </p>			
			<div className={styles.action_button_wrapper}>
				{ props.cchSettings?.connectionDetail?.status ?
					<div className={styles.connectionstatus}>
						<div> 
							<span>Logged in as </span>
							<span className={styles.connected}>{props.cchSettings?.connectionDetail?.userName}</span>
						</div> 
						<div>
							<Button
								data-test-auto='49aa4626-e4df-4f68-b9de-352bff00304e'
								variant='secondary'
								className={styles.disconnect}
								onClick={() => {
									showDisconnectAlertPopUp();
								}}>
								Disconnect
							</Button>
						</div>
					</div>
					: <ButtonWithTextIcon
						label='Login to CCH'
						className={styles.action_button} 
						onClick={onLogin}
					/>
				}
			</div> 
			{
				props.cchSettings.eFileSettings.eFileFeatureEnabled &&
					<>
						<h3 className='suitePageSectionSubHeader'>Allow E-file</h3>
						<p className='suitePageSectionDescription'>
							By enabling this feature, you can release tax returns uploaded
							to the CCH Axcess EFS from Tax Returns.
						</p>
						<div className='switchSettingsBlock form-switch'>
							<input
								className='switchSettingsButton form-check-input'
								type='checkbox'
								id='isEfileSettingsEnabled'
								data-test-auto='ba540f1e-e9cc-4bf2-af0d-e59d8539c674'
								checked={eFileAllowed}
								onChange={handleToggleSwitchChange}
							/>
							{isEfileAllowed && (
								<span
									className='switchSettingsIcon'
									data-test-auto='cab7bf20-5186-47f5-81d0-3eafb94ef93f'>
								</span>
							)}
							<span className='switchSettingsText'>E-file</span>
						</div>
					</> 
			}

            {showDisconnectAlert && (
				<SuiteModal
					width='600'
					theme='light'
					title='Disconnect'
					hide={() => setShowDisconnectAlert(false)}>
					<DisconnectModal
						onDisconnectAlertNo={onDisconnectAlertNo}
						onDisconnectAlertYes={onDisconnectAlertYes}
					/>
				</SuiteModal>
			)}
		</article>
    );
} 