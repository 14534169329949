import { Action, Reducer } from "redux";
import { actionTypes } from "./notification.types";

export enum StatusType {
  None,
  Error,
  Success,
  Warning,
}

export interface NotificationState {
  message: string;
  type: StatusType;
  identifier: Date;
}

export interface NotificationAction {
  type: actionTypes.NOTIFICATION;
  statusMessage: string;
  statusType: StatusType;
}

type DispatchAction = NotificationAction;

export const actionCreators = {
  notification: (statusMessage: string, statusType: StatusType): NotificationAction => ({
    type: actionTypes.NOTIFICATION,
    statusMessage,
    statusType,
  }),
};

const unloadedState: NotificationState = {} as NotificationState;

export const reducer: Reducer<NotificationState> = (
  state: NotificationState = unloadedState,
  incomingAction: Action
) => {
  const action = incomingAction as DispatchAction;
  switch (action.type) {
    case actionTypes.NOTIFICATION:
      const notification: NotificationState = { ...state };
      notification.message = action.statusMessage;
      notification.type = action.statusType;
      notification.identifier = new Date();
      return notification;
    default:
  }
  return state || unloadedState;
};
