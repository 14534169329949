import { UserManager } from "oidc-client";
import Config from "./config";
import { storeUserError, storeUser } from "../pages/common/auth/auth.actions";
import { createUserManagerWithRedirect } from "./helpers";
import { createForethoughtCookie, resetCookie } from "../helper/HelperFunctions";
import { updateLogoutDate } from "src/pages/common/auth/auth.apis";

export let userManager = new UserManager(Config);

export async function loadUserFromStorage(store: any) {
  try {
    const user = await userManager.getUser();
    if (!user) {
      return store.dispatch(storeUserError());
    }
    store.dispatch(storeUser(user));
  } catch (e) {
    console.error(`User not found: ${e}`);
    store.dispatch(storeUserError());
  }
}

export function signinRedirect() {
  return userManager.signinRedirect();
}
export const signinRedirectWithCallbackArgs = (args: string) => {
  if (args && !userManager.settings.redirect_uri?.includes("?callback_args=")) {
    const redirectUri = userManager.settings.redirect_uri + "?callback_args=" + encodeURIComponent(args)
    userManager = createUserManagerWithRedirect(redirectUri);
  }
  userManager.signinRedirect();
}
export function signinRedirectCallback() {
    return userManager.signinRedirectCallback();
}

export async function signoutRedirect() {
  const user = await userManager.getUser();
  userManager.clearStaleState();
  userManager.removeUser();
  resetCookie('userAutoLogout','0');
  createForethoughtCookie('isForethoughtWidgetVisible', 'false');
  await updateLogoutDate();
  return userManager.signoutRedirect({ id_token_hint: user?.id_token });
}

export function signoutRedirectCallback() {
  userManager.clearStaleState();
  userManager.removeUser();
    return userManager.signoutRedirectCallback();
}

export default userManager;
