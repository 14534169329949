import React, { FC } from 'react';
import { Modal, Container, Button } from 'react-bootstrap';
import './Disconnect.style.scss';

interface DisconnectModalProps {
	onDisconnectAlertNo: () => void;
	onDisconnectAlertYes: () => void;
}
const DisconnectModal: FC<DisconnectModalProps> = (props) => {
	return (
		<>
			<Modal.Body className='show-grid'>
				<Container>
					<div className='disableMessage'>
					By disconnecting the active CCH Axcess account, all users will lose the ability to import and/or e-file from SafeSend. Any in-process imports or e-filing from CCH Axcess will fail. 
					<br /><br />
					Do you wish to continue? 
					</div>
				</Container>
			</Modal.Body>
			<Modal.Footer>
				<Button
					data-test-auto='45ba105b-d383-4bdd-87e5-a6d3bdc085cf'
					variant='secondary'
					onClick={props.onDisconnectAlertNo}>
					Cancel
				</Button>
				<Button
					data-test-auto='c6568a96-e92e-4d53-afc9-314ffc474bf8'
					variant='danger'
					onClick={props.onDisconnectAlertYes}>
					Disconnect
				</Button>
			</Modal.Footer>
		</>
	);
};

export default DisconnectModal;
