import React, { FC, useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import DragAndDrop from 'src/components/common/DragAndDrop';
import OverlayLoader from 'src/components/common/OverlayLoader';
import StepInProgressIndicator from 'src/components/common/StepInProgressIndicator';
import LeftAngleIcon from 'src/components/svg/LeftAngleIcon';
import { OfficeLocationConstants, StateUSA } from 'src/helper/Constants';
import {
	formatUSNumber,
	isValidFiveDigitNumber,
	isValidNumber,
	onlyAlphabetsAndSpaces,
} from 'src/helper/formValidations';
import { ValidateWebsite } from 'src/helper/Validations';
import { getAllUsersWithLocation } from 'src/modules/userManagement/users/Users.api';
import { IDefaultContactPerson } from 'src/modules/userManagement/users/Users.model';
import { StatusType } from 'src/pages/common/notification/notification.reducer';
import { actionTypes as notificationTypes } from 'src/pages/common/notification/notification.types';
import { type ICountryState } from 'src/pages/company-management/company-management.types';

import { IDragAndDropData } from '../../../../components/common/DragAndDrop/DragAndDrop.model';
import { IUserLocation } from '../../../userManagement/users/Users.model';
import OfficeInformation, { IStateOptions } from '../office-information';
import {
	addOfficeLocation,
	getUserIDsInLocation,
	updateOfficeLocation,
	validateEditLocationRequest,
} from './save-office-location-modal.api';
import styles from './save-office-location-modal.module.scss';
import {
	type IAddLocationResponse,
	IEditOfficeLocationResponse,
	type IGetUserIDsInLocationResponse,
	initialOfficeLocationData,
	initialStepData,
	type IOfficeLocationDataModel,
	type IOfficeLocationErrorsModel,
	type IOfficeModel,
	type IStepData,
	secondStepData,
	IDisabledDnDItems,
	IOfficeLocationDropdownModel,
} from './save-office-location-modal.types';
import { logger } from 'src/oidcClient/authProvider';
import { isValidMobileNumber } from '@sssuite-js-packages/mobile-number-validator';

interface SaveOfficeLocationModalProps {
	onHide: () => void;
	officeLocationToEdit?: null | IOfficeModel;
	getAllCompanyList: (pageNumber: number, pageSize: number, searchText: string, newLocationIds: string) => void;
	newOfficeLocationIds: number[];
	setNewOfficeLocationIds: (newOfficeLocationIds: number[]) => void;
	isOfficeLocationToEditPrimary: boolean;
	currentPage: number;
	setCurrentPage: (currentPage: number) => void;
	officeLocationErrors: IOfficeLocationErrorsModel;
	setOfficeLocationErrors: (officeLocationErrors: IOfficeLocationErrorsModel) => void;
	fetchEntireListOfOfficeLocation: () => void;
	setEditedOfficeLocation: (value: IOfficeLocationDataModel) => void;
	setShowContactPersonModal: (value: boolean) => void;
	removedUsers: IDragAndDropData<string, number, string | undefined>[];
	setRemovedUsers: (value: IDragAndDropData<string, number, string | undefined>[]) => void;
	setContactPersons: (value: IDefaultContactPerson[]) => void;
	setShowSaveOfficeLocation: (value: string) => void;
	officeLocationList: IOfficeLocationDropdownModel[];
}
const SaveOfficeLocationModal: FC<SaveOfficeLocationModalProps> = (props) => {
	const {
		onHide,
		officeLocationToEdit,
		getAllCompanyList,
		newOfficeLocationIds,
		setNewOfficeLocationIds,
		isOfficeLocationToEditPrimary,
		currentPage,
		setCurrentPage,
		officeLocationErrors,
		setOfficeLocationErrors,
		fetchEntireListOfOfficeLocation,
		setEditedOfficeLocation,
		setShowContactPersonModal,
		setContactPersons,
		removedUsers,
		setRemovedUsers,
		setShowSaveOfficeLocation,
		officeLocationList,
	} = props;

	const [stepData, setStepData] = useState<IStepData[]>(initialStepData);
	const [officeLocation, setOfficeLocation] = useState<IOfficeModel>(
		officeLocationToEdit ? officeLocationToEdit : initialOfficeLocationData,
	);

	const [totalUsersFromDB, setTotalUsersFromDB] = useState<IDragAndDropData<string, number, string | undefined>[]>([]);
	const [availableUsers, setAvailableUsers] = useState<IDragAndDropData<string, number, string | undefined>[]>([]);
	const [usersInLocation, setUsersInLocation] = useState<IDragAndDropData<string, number, string | undefined>[]>([]);
	const [availableUsersInitial, setAvailableUsersInitial] = useState<
		IDragAndDropData<string, number, string | undefined>[]
	>([]);
	const [usersInLocationInitial, setUsersInLocationInitial] = useState<
		IDragAndDropData<string, number, string | undefined>[]
	>([]);
	const [loadingOfficeLocationModal, setLoadingOfficeLocationModal] = useState(false);
	const [userIDsInLocationFromDB, setUserIDsInLocationFromDB] = useState<number[]>([]);
	const [noUserAddedError, setNoUserAddedError] = useState(false);
	const [isOfficeInformationDataChanged, setIsOfficeInformationDataChanged] = useState(false);
	const [isUsersDataChanged, setIsUsersDataChanged] = useState(false);
	const [initialLocationName, setInitialLocationName] = useState<string>(
		officeLocationToEdit ? officeLocationToEdit.name : '',
	);
	const [selectedSourceList, setSelectedSourceList] = useState<IDragAndDropData<string, number, string | undefined>[]>(
		[],
	);
	const [selectedDestinationList, setSelectedDestinationList] = useState<
		IDragAndDropData<string, number, string | undefined>[]
	>([]);

	const dispatch = useDispatch();

	const companyData = useSelector((store) => {
		return store?.companyData;
	});
	const primaryAdminId: number = companyData?.companyProfile.companyInfo.adminUser;
	const disableDestinationItems: IDisabledDnDItems[] = [];
	const disabledItem: IDisabledDnDItems = {
		id: primaryAdminId,
		disabledTitle: OfficeLocationConstants.disablePrimaryAdminDnD,
	};
	disableDestinationItems.push(disabledItem);

	useEffect(() => {
		setLoadingOfficeLocationModal(true);
		getAllUsersWithLocation((totalUsersListFromDB: IUserLocation[]) => {
			let users: IDragAndDropData<string, number, string | undefined>[] = totalUsersListFromDB.map((x) => {
				return { name: x.name, value: x.value, prop3: x.locationIds };
			});
			setTotalUsersFromDB(users);
			if (officeLocation.locationId !== -1)
				getUserIDsInLocation(officeLocation.locationId, (responseData: IGetUserIDsInLocationResponse) => {
					setLoadingOfficeLocationModal(false);
					if (responseData.error) {
						onHide();
						dispatch({
							type: notificationTypes.NOTIFICATION,
							statusType: StatusType.Warning,
							statusMessage: OfficeLocationConstants.getUserIDsInLocationError,
						});
					} else setUserIDsInLocationFromDB(responseData.userIDsInLocation);
				});
		});
	}, []);

	useEffect(() => {
		return () => {
			setOfficeLocationErrors({
				name: { error: false, message: '' },
				countryCode: { error: false, message: '' },
				address1: { error: false, message: '' },
				address2: { error: false, message: '' },
				city: { error: false, message: '' },
				state: { error: false, message: '' },
				zip: { error: false, message: '' },
				phone: { error: false, message: '' },
				fax: { error: false, message: '' },
				website: { error: false, message: '' },
			});
		};
	}, []);

	useEffect(() => {
		usersInLocation.length > 0 ? setNoUserAddedError(false) : setNoUserAddedError(true);
	}, [usersInLocation]);

	useEffect(() => {
		const filteredAvailableUsers: IDragAndDropData<string, number, string | undefined>[] = [];
		const usersInLocationArray: IDragAndDropData<string, number, string | undefined>[] = [];
		totalUsersFromDB.forEach((eachUser: IDragAndDropData<string, number, string | undefined>) => {
			if (eachUser.value === primaryAdminId) usersInLocationArray.push(eachUser);
			else if (userIDsInLocationFromDB.includes(eachUser.value)) usersInLocationArray.push(eachUser);
			else filteredAvailableUsers.push(eachUser);
		});
		setAvailableUsers(filteredAvailableUsers);
		setAvailableUsersInitial(filteredAvailableUsers);
		setUsersInLocation(usersInLocationArray);
		setUsersInLocationInitial(usersInLocationArray);
		setLoadingOfficeLocationModal(false);
	}, [totalUsersFromDB, userIDsInLocationFromDB]);

	const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		setOfficeLocation({ ...officeLocation, name: event.target.value });
		setIsOfficeInformationDataChanged(true);
	};
	const handleOnBlurName = (event?: React.ChangeEvent<HTMLInputElement>): void => {
		const target = event ? event.target : document.getElementById('name');
		const newOfficeLocationErrors = { ...officeLocationErrors };
		if (target?.value.trim().length === 0) {
			newOfficeLocationErrors.name.error = true;
			newOfficeLocationErrors.name.message = 'This field is required';
			setOfficeLocationErrors(newOfficeLocationErrors);
		} else {
			newOfficeLocationErrors.name.error = false;
			newOfficeLocationErrors.name.message = '';
			setOfficeLocationErrors(newOfficeLocationErrors);
			setOfficeLocation({ ...officeLocation, name: target?.value.trim() });
		}
	};

	const handleDuplicateLocationName = (event?: React.ChangeEvent<HTMLInputElement>): void => {
		const target = event ? event.target : document.getElementById('name');
		const newOfficeLocationErrors = { ...officeLocationErrors };
		if (
			(officeLocation.locationId === -1 &&
				officeLocationList.filter((x) => x.name.toLocaleLowerCase() === target?.value.trim().toLocaleLowerCase())
					.length > 0) ||
			(initialLocationName.toLocaleLowerCase() === officeLocation.name.trim().toLocaleLowerCase() &&
				officeLocationList.filter((x) => x.name.toLocaleLowerCase() === target?.value.trim().toLocaleLowerCase())
					.length > 1) ||
			(initialLocationName.toLocaleLowerCase() !== officeLocation.name.trim().toLocaleLowerCase() &&
				officeLocationList.filter((x) => x.name.toLocaleLowerCase() === target?.value.trim().toLocaleLowerCase())
					.length > 0)
		) {
			newOfficeLocationErrors.name.error = true;
			newOfficeLocationErrors.name.message = OfficeLocationConstants.duplicateOfficeLocationName;
			setOfficeLocationErrors(newOfficeLocationErrors);
		} else {
			newOfficeLocationErrors.name.error = false;
			newOfficeLocationErrors.name.message = '';
			setOfficeLocationErrors(newOfficeLocationErrors);
			setOfficeLocation({ ...officeLocation, name: target?.value.trim() });
		}
	};

	const handleAddress1Change = (event: React.ChangeEvent<HTMLInputElement>): void => {
		setOfficeLocation({ ...officeLocation, address1: event.target.value });
		setIsOfficeInformationDataChanged(true);
	};
	const handleOnBlurAddress1 = (event?: React.ChangeEvent<HTMLInputElement>): void => {
		const target = event ? event.target : document.getElementById('address1');
		const newOfficeLocationErrors = { ...officeLocationErrors };
		if (target?.value.trim().length === 0) {
			newOfficeLocationErrors.address1.error = true;
			newOfficeLocationErrors.address1.message = 'This field is required';
			setOfficeLocationErrors(newOfficeLocationErrors);
		} else {
			newOfficeLocationErrors.address1.error = false;
			newOfficeLocationErrors.address1.message = '';
			setOfficeLocationErrors(newOfficeLocationErrors);
			setOfficeLocation({ ...officeLocation, address1: target?.value.trim() });
		}
	};

	const handleAddress2Change = (event: React.ChangeEvent<HTMLInputElement>): void => {
		setOfficeLocation({ ...officeLocation, address2: event.target.value });
		setIsOfficeInformationDataChanged(true);
	};
	const handleOnBlurAddress2 = (event?: React.ChangeEvent<HTMLInputElement>): void => {
		const target = event ? event.target : document.getElementById('address2');
		setOfficeLocation({ ...officeLocation, address2: target?.value.trim() });
	};

	const handleCityChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		if (onlyAlphabetsAndSpaces(event.target.value)) setOfficeLocation({ ...officeLocation, city: event.target.value });
		setIsOfficeInformationDataChanged(true);
	};
	const handleOnBlurCity = (event?: React.ChangeEvent<HTMLInputElement>): void => {
		const target = event ? event.target : document.getElementById('city');
		const newOfficeLocationErrors = { ...officeLocationErrors };
		if (target?.value.trim().length === 0) {
			newOfficeLocationErrors.city.error = true;
			newOfficeLocationErrors.city.message = 'This field is required';
			setOfficeLocationErrors(newOfficeLocationErrors);
		} else {
			newOfficeLocationErrors.city.error = false;
			newOfficeLocationErrors.city.message = '';
			setOfficeLocationErrors(newOfficeLocationErrors);
			setOfficeLocation({ ...officeLocation, city: target?.value.trim() });
		}
	};

	const handleStateChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
		setOfficeLocation({ ...officeLocation, state: Number(event.target.value) });
		setIsOfficeInformationDataChanged(true);
	};
	const handleOnBlurState = (event?: React.ChangeEvent<HTMLSelectElement>): void => {
		const target = event ? event.target : document.getElementById('state');
		const newOfficeLocationErrors = { ...officeLocationErrors };
		if (Number(target?.value) === 0) {
			newOfficeLocationErrors.state.error = true;
			newOfficeLocationErrors.state.message = 'This field is required';
			setOfficeLocationErrors(newOfficeLocationErrors);
		} else {
			newOfficeLocationErrors.state.error = false;
			newOfficeLocationErrors.state.message = '';
			setOfficeLocationErrors(newOfficeLocationErrors);
		}
	};

	const handleZipChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		if (isValidFiveDigitNumber(event.target.value)) setOfficeLocation({ ...officeLocation, zip: event.target.value });
		setIsOfficeInformationDataChanged(true);
	};
	const handleOnBlurZip = (event?: React.ChangeEvent<HTMLInputElement>): void => {
		const target = event ? event.target : document.getElementById('zip');
		const newOfficeLocationErrors = { ...officeLocationErrors };
		if (target?.value.trim().length === 0) {
			newOfficeLocationErrors.zip.error = true;
			newOfficeLocationErrors.zip.message = 'This field is required';
			setOfficeLocationErrors(newOfficeLocationErrors);
		} else if (target?.value.trim().length < 5) {
			newOfficeLocationErrors.zip.error = true;
			newOfficeLocationErrors.zip.message = 'Please enter a 5 digit zip';
			setOfficeLocationErrors(newOfficeLocationErrors);
		} else {
			newOfficeLocationErrors.zip.error = false;
			newOfficeLocationErrors.zip.message = '';
			setOfficeLocationErrors(newOfficeLocationErrors);
		}
	};

	const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		if (isValidNumber(event.target.value)) {
			const phoneNumber = event.target.value.length === 10 ? formatUSNumber(event.target.value) : event.target.value;
			setOfficeLocation({ ...officeLocation, phone: phoneNumber });
		} else setOfficeLocation({ ...officeLocation, phone: '' });
		setIsOfficeInformationDataChanged(true);
	};
	const handleOnBlurPhone = (event?: React.ChangeEvent<HTMLInputElement>): void => {
		const target = event ? event.target : document.getElementById('phone');
		const newOfficeLocationErrors = { ...officeLocationErrors };
		if (target?.value.trim().length > 0 && !isValidMobileNumber('+1', target?.value.trim())) {
			newOfficeLocationErrors.phone.error = true;
			newOfficeLocationErrors.phone.message =
				'Please enter a valid mobile number, according to the country code selected';
			setOfficeLocationErrors(newOfficeLocationErrors);
		} else {
			newOfficeLocationErrors.phone.error = false;
			newOfficeLocationErrors.phone.message = '';
			setOfficeLocationErrors(newOfficeLocationErrors);
		}
	};

	const handleFaxChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		if (isValidNumber(event.target.value)) {
			const faxNumber = event.target.value.length === 10 ? formatUSNumber(event.target.value) : event.target.value;
			setOfficeLocation({ ...officeLocation, fax: faxNumber });
		} else setOfficeLocation({ ...officeLocation, fax: '' });
		setIsOfficeInformationDataChanged(true);
	};
	const handleOnBlurFax = (event?: React.ChangeEvent<HTMLInputElement>): void => {
		const target = event ? event.target : document.getElementById('fax');
		const newOfficeLocationErrors = { ...officeLocationErrors };
		if (target?.value.trim().length > 0 && !isValidMobileNumber('+1', target?.value.trim())) {
			newOfficeLocationErrors.fax.error = true;
			newOfficeLocationErrors.fax.message = 'Please enter a valid fax number, according to the country code selected';
			setOfficeLocationErrors(newOfficeLocationErrors);
		} else {
			newOfficeLocationErrors.fax.error = false;
			newOfficeLocationErrors.fax.message = '';
			setOfficeLocationErrors(newOfficeLocationErrors);
		}
	};

	const handleWebsiteChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		setOfficeLocation({ ...officeLocation, website: event.target.value.trim() });
		setIsOfficeInformationDataChanged(true);
	};
	const handleOnBlurWebsite = (event?: React.ChangeEvent<HTMLInputElement>): void => {
		const target = event ? event.target : document.getElementById('website');
		const newOfficeLocationErrors = { ...officeLocationErrors };
		if (target?.value.trim().length > 0 && !ValidateWebsite(target?.value.trim())) {
			newOfficeLocationErrors.website.error = true;
			newOfficeLocationErrors.website.message = 'Please enter valid website';
			setOfficeLocationErrors(newOfficeLocationErrors);
		} else {
			newOfficeLocationErrors.website.error = false;
			newOfficeLocationErrors.website.message = '';
			setOfficeLocationErrors(newOfficeLocationErrors);
		}
	};

	const setIsPrimaryLocation = (event: React.ChangeEvent<HTMLInputElement>): void => {
		setOfficeLocation({ ...officeLocation, isPrimary: event.target.checked });
		setIsOfficeInformationDataChanged(true);
	};

	const getArrayOfStateOptions = (): IStateOptions[] => {
		const countryStates: ICountryState[] = companyData?.companyProfile.countryStates;
		const stateOptions: IStateOptions[] = countryStates.map((eachCountry: ICountryState) => {
			return { value: eachCountry.id.toString(), name: StateUSA[eachCountry.name] };
		});
		const stateOptionsFiltered: IStateOptions[] = stateOptions.filter((eachCountry: IStateOptions) => {
			return eachCountry.name && eachCountry.value;
		});
		return stateOptionsFiltered;
	};

	const checkMandatoryFields = (): boolean => {
		handleOnBlurName();
		handleDuplicateLocationName();
		handleOnBlurAddress1();
		handleOnBlurCity();
		handleOnBlurState();
		handleOnBlurZip();
		handleOnBlurPhone();
		handleOnBlurFax();
		handleOnBlurWebsite();
		for (const field in officeLocationErrors)
			if (officeLocationErrors[field as keyof typeof officeLocationErrors].error) return false;

		return true;
	};
	const submitOfficeInformation = (): void => {
		if (checkMandatoryFields()) {
			setStepData(secondStepData);
			setNoUserAddedError(false);
		}
	};

	const checkIfFormChanged = (): void => {
		const isDisabled = loadingOfficeLocationModal || (!isOfficeInformationDataChanged && !isUsersDataChanged);
		if (!isDisabled) handleAddOrUpdateLocation();
	};

	const handleAddOrUpdateLocation = (): void => {
		if (usersInLocation.length > 0) {
			setNoUserAddedError(false);
			setLoadingOfficeLocationModal(true);
			if (officeLocation.phone) officeLocation.phone = officeLocation.phone.replace(/\D/g, '');
			else officeLocation.phone = '';

			if (officeLocation.fax) officeLocation.fax = officeLocation.fax.replace(/\D/g, '');
			else officeLocation.fax = '';

			if (!officeLocation.address2) officeLocation.address2 = '';
			if (!officeLocation.website) officeLocation.website = '';

			officeLocation.countryCode = '+1';
			const userInLocationIds: number[] = usersInLocation.map((each) => each.value);
			const officeLocationData: IOfficeLocationDataModel = {
				officeLocation,
				userIds: userInLocationIds,
			};
			if (officeLocation.locationId === -1) {
				logger && logger.trackTrace('handleAddOrUpdateLocation: Adding office location');
				addOfficeLocation(officeLocationData, (responseData: IAddLocationResponse) => {
					setLoadingOfficeLocationModal(false);
					if (responseData.error)
						dispatch({
							type: notificationTypes.NOTIFICATION,
							statusType: StatusType.Warning,
							statusMessage: OfficeLocationConstants.addOfficeLocationError,
						});
					else {
						const officeLocationIdsPrepared = [...newOfficeLocationIds];
						officeLocationIdsPrepared.unshift(responseData.locationId);
						setNewOfficeLocationIds(officeLocationIdsPrepared);
						setCurrentPage(1);
						onHide();
						dispatch({
							type: notificationTypes.NOTIFICATION,
							statusType: StatusType.Success,
							statusMessage: OfficeLocationConstants.addOfficeLocationSuccess,
						});
						getAllCompanyList(1, 7, '', officeLocationIdsPrepared.join(','));
						fetchEntireListOfOfficeLocation();
					}
				});
			} else {
				logger && logger.trackTrace('handleAddOrUpdateLocation: Editing office location');
				setEditedOfficeLocation(officeLocationData);
				const data = {
					locationId: officeLocationData.officeLocation.locationId,
					removedUsers: removedUsers.map((x) => x.value),
				};
				if (removedUsers.length > 0)
					validateEditLocationRequest(data, (response: IEditOfficeLocationResponse) => {
						if (!response.error && !response.isContactPerson) updateLocation(officeLocationData);
						else if (response.error) {
							setLoadingOfficeLocationModal(false);
							onHide();
							dispatch({
								type: notificationTypes.NOTIFICATION,
								statusType: StatusType.Warning,
								statusMessage: OfficeLocationConstants.updateOfficeLocationError,
							});
						} else {
							setLoadingOfficeLocationModal(false);
							setShowSaveOfficeLocation('');
							setShowContactPersonModal(true);
							setContactPersons(response.contactPersons);
						}
					});
				else updateLocation(officeLocationData);
			}
		} else setNoUserAddedError(true);
	};

	const updateLocation = (officeLocationData: IOfficeLocationDataModel) => {
		updateOfficeLocation(officeLocationData, (response: boolean) => {
			setLoadingOfficeLocationModal(false);
			if (response) {
				onHide();
				dispatch({
					type: notificationTypes.NOTIFICATION,
					statusType: StatusType.Success,
					statusMessage: OfficeLocationConstants.updateOfficeLocationSuccess,
				});
				getAllCompanyList(currentPage, 7, '', newOfficeLocationIds.join(','));
				fetchEntireListOfOfficeLocation();
			} else
				dispatch({
					type: notificationTypes.NOTIFICATION,
					statusType: StatusType.Warning,
					statusMessage: OfficeLocationConstants.updateOfficeLocationError,
				});
		});
	};

	const setUsersRemoved = (value: IDragAndDropData<string, number, string | undefined>[]) => {
		let removedList: IDragAndDropData<string, number, string | undefined>[] = [];
		value = value.filter((x, index) => value.findIndex((y) => y.value == x.value) === index);
		if (removedUsers.length == 0)
			value.forEach((x: IDragAndDropData<string, number, string | undefined>) => {
				removedList.push(x);
			});
		else {
			removedList = [...removedUsers];
			value.forEach((x: IDragAndDropData<string, number, string | undefined>) => {
				if (
					removedUsers.findIndex((z: IDragAndDropData<string, number, string | undefined>) => z.value == x.value) == -1
				)
					removedList = [...removedList, x];
			});
		}
		setRemovedUsers(removedList);
	};

	return (
		<>
			<OverlayLoader />
			<Modal.Body className='show-grid'>
				<div className={styles.saveOfficeLocationModal}>
					<StepInProgressIndicator
						data={stepData}
						connectorLength='100px'
					/>
					<div className={styles.officeLocationContent}>
						{stepData[0].inProgress && (
							<OfficeInformation
								officeLocation={officeLocation}
								officeLocationErrors={officeLocationErrors}
								handleNameChange={handleNameChange}
								handleOnBlurName={handleOnBlurName}
								handleAddress1Change={handleAddress1Change}
								handleOnBlurAddress1={handleOnBlurAddress1}
								handleAddress2Change={handleAddress2Change}
								handleOnBlurAddress2={handleOnBlurAddress2}
								handleCityChange={handleCityChange}
								handleOnBlurCity={handleOnBlurCity}
								handleStateChange={handleStateChange}
								handleOnBlurState={handleOnBlurState}
								handleZipChange={handleZipChange}
								handleOnBlurZip={handleOnBlurZip}
								handlePhoneChange={handlePhoneChange}
								handleOnBlurPhone={handleOnBlurPhone}
								handleFaxChange={handleFaxChange}
								handleOnBlurFax={handleOnBlurFax}
								handleWebsiteChange={handleWebsiteChange}
								handleOnBlurWebsite={handleOnBlurWebsite}
								stateOptions={getArrayOfStateOptions()}
							/>
						)}
						{stepData[1].inProgress && (
							<div className={styles.officeUsers}>
								<DragAndDrop<string, number, string | undefined, number | null>
									sourceData={availableUsers}
									setSourceData={setAvailableUsers}
									destinationData={usersInLocation}
									setDestinationData={setUsersInLocation}
									sourceDataInitial={availableUsersInitial}
									destinationDataInitial={usersInLocationInitial}
									sourceHeading='AVAILABLE USERS'
									sourceHeadingInfoText='List of all users in company'
									destinationHeading='USERS IN LOCATION'
									destinationHeadingInfoText='List of users selected in this location'
									showResetButton={true}
									setRemovedUsers={setUsersRemoved}
									data={officeLocation.locationId}
									disableDestinationItems={disableDestinationItems}
									setIsDataChanged={setIsUsersDataChanged}
									selectedSourceList={selectedSourceList}
									setSelectedSourceList={setSelectedSourceList}
									selectedDestinationList={selectedDestinationList}
									setSelectedDestinationList={setSelectedDestinationList}
								/>
								{noUserAddedError && (
									<div className={styles.noUserAddedError}>Office Location cannot be created without users</div>
								)}
							</div>
						)}
					</div>
				</div>
			</Modal.Body>
			<Modal.Footer className={styles.officeLocationFooter}>
				<div className={styles.leftSideFooter}>
					{stepData[0].inProgress && (
						<div className={styles.primaryLocation}>
							<span className={styles.check}>
								<input
									type='checkbox'
									name='primaryLocation'
									id='primaryLocation'
									checked={officeLocation.isPrimary}
									disabled={officeLocation.locationId !== -1 && isOfficeLocationToEditPrimary}
									onChange={setIsPrimaryLocation}
								/>
							</span>
							<label
								className={styles.checkText}
								htmlFor='primaryLocation'>
								Set as Primary Location
							</label>
						</div>
					)}
					{stepData[1].inProgress && (
						<Button
							disabled={loadingOfficeLocationModal}
							data-test-auto='dec3854f-a6b7-48e0-af54-bfcbb919a99f'
							variant='secondary'
							className={styles.backButton}
							onClick={() => {
								setStepData(initialStepData);
							}}>
							<LeftAngleIcon />
							Back
						</Button>
					)}
				</div>
				<div className={styles.rightSideFooter}>
					<Button
						disabled={loadingOfficeLocationModal}
						data-test-auto='30489fde-c164-41ac-8140-39800266e2c8'
						variant='secondary'
						className={styles.cancelButton}
						onClick={onHide}>
						Cancel
					</Button>
					{stepData[0].inProgress && (
						<Button
							disabled={loadingOfficeLocationModal}
							data-test-auto='f9ab0c76-2cb9-41a5-8fc5-499cbf86661f'
							variant='primary'
							className={styles.nextButton}
							onClick={submitOfficeInformation}>
							Next
						</Button>
					)}
					{stepData[1].inProgress && officeLocation.locationId === -1 && (
						<Button
							disabled={loadingOfficeLocationModal}
							data-test-auto='3b00e1f3-fb8f-48e9-a0ce-b0eb6975daa0'
							variant='primary'
							className={styles.addButton}
							onClick={handleAddOrUpdateLocation}>
							Add Location
						</Button>
					)}
					{stepData[1].inProgress && officeLocation.locationId !== -1 && (
						<Button
							data-test-auto='dc3925da-e26e-4078-9abd-ef096382f01e'
							variant='primary'
							className={`${styles.updateButton} ${
								loadingOfficeLocationModal || (!isOfficeInformationDataChanged && !isUsersDataChanged)
									? styles.disabledUpdateButton
									: ''
							}`}
							onClick={checkIfFormChanged}>
							Update
						</Button>
					)}
				</div>
			</Modal.Footer>
		</>
	);
};

export default SaveOfficeLocationModal;
