import React, { useEffect, useRef, useState } from 'react';
import InfoIcon from 'src/components/svg/InfoIcon';
import { IDisabledDnDItems } from 'src/modules/company-management/partials/save-office-location-modal/save-office-location-modal.types';
import { IDragAndDropData } from '../DragAndDrop.model';
import DraggableList from '../DraggableList';
import styles from '../DragAndDrop.module.scss';

interface DestinationProps<T1 extends string, T2, T3 = undefined, T4 = null> {
	destinationData: IDragAndDropData<T1, T2, T3 | undefined>[];
	sourceData: IDragAndDropData<T1, T2, T3 | undefined>[];
	handleDropChange: (
		destinationData: IDragAndDropData<T1, T2, T3 | undefined>[],
		sourceData: IDragAndDropData<T1, T2, T3 | undefined>[],
	) => void;
	destinationHeading?: string;
	destinationHeadingInfoText?: string;
	destinationWidth?: string;
	destinationHeight?: string;
	data?: T4;
	disableDestinationItems?: IDisabledDnDItems[];
	selectedDestinationList: IDragAndDropData<T1, T2, T3 | undefined>[];
	setSelectedDestinationList: (selectedDestinationList: IDragAndDropData<T1, T2, T3 | undefined>[]) => void;
}

function Destination<T1 extends string, T2, T3 = undefined, T4 = null>(props: DestinationProps<T1, T2, T3, T4>) {
	const {
		destinationData,
		sourceData,
		handleDropChange,
		destinationHeading,
		destinationHeadingInfoText,
		destinationWidth,
		destinationHeight,
		data,
		disableDestinationItems,
		selectedDestinationList,
		setSelectedDestinationList,
	} = props;
	const [destination, setDestination] = useState<any[]>([]);
	const [filteredDestinationList, setFilteredDestinationList] = useState<any[]>([]);

	const selectAllRef: React.RefObject<HTMLInputElement> = useRef(null);

	useEffect(() => {
		setDestination(destinationData);
		const newDestinationList = destinationData.filter(
			(x: any) => !disableDestinationItems?.some((ele) => ele.id === x.value) && !checkDisablity(x),
		);
		setFilteredDestinationList(newDestinationList);
	}, [destinationData]);

	useEffect(() => {
		toggleSelectAllCheckbox();
	}, [selectedDestinationList, destination]);

	const dragOverHandler = (event: any) => {
		event.preventDefault();
	};

	const dropHandler = (event: any) => {
		const data = JSON.parse(event.dataTransfer.getData('text'));
		if (
			destinationData.some((ele: any) => data.findIndex((datum: any) => datum.value === ele.value) === -1) ||
			!destinationData.length
		) {
			const newDestinationList = [...data, ...destinationData];
			const newSourceList = sourceData.filter(
				(ele: any) => !data.some((eachData: any) => eachData.value === ele.value),
			);
			handleDropChange(newDestinationList, newSourceList);
		}
	};

	const handleClickAll = (event: any) => {
		if (event.target.checked) {
			setSelectedDestinationList([...filteredDestinationList]);
		} else {
			setSelectedDestinationList([]);
		}
	};

	const toggleSelectAllCheckbox = () => {
		if (selectAllRef.current) {
			if (selectedDestinationList.length === 0) {
				selectAllRef.current.checked = false;
			} else {
				const isAllSelected =
				filteredDestinationList.length === selectedDestinationList.length &&
				filteredDestinationList.every((item) => selectedDestinationList.includes(item));
				selectAllRef.current.checked = isAllSelected;
			}
		}
	};

	const checkDisablity = (datum: IDragAndDropData<T1, T2, T3 | undefined>) =>
		datum.prop3 &&
		((typeof datum.prop3 === 'string' &&
			datum.prop3?.split(',').length === 1 &&
			datum.prop3?.split(',')[0]?.trim() === data?.toString()) ||
			(typeof datum.prop3 === 'string' && datum.prop3 === data?.toString()) ||
			(typeof datum.prop3 === 'number' && datum.prop3 === data));

	return (
		<article
			className='destinationDataContainer'
			style={destinationWidth ? { width: destinationWidth } : null}>
			{destinationHeading && (
				<div className='destinationDataHeaderDiv'>
					<span className='destinationDataHeader'>{destinationHeading}</span>
					{destinationHeadingInfoText && (
						<span
							className='destinationDataInfo'
							title={destinationHeadingInfoText}>
							<InfoIcon />
						</span>
					)}
				</div>
			)}
			{
				<div className={styles.selectAllSection}>
					<input
						type='checkbox'
						id='selectAllDestination'
						className={`${styles.selectAllSection_input} ${filteredDestinationList.length ? '' : styles.disabled}`}
						ref={selectAllRef}
						onChange={(e: any) => handleClickAll(e)}
						disabled={!filteredDestinationList.length}
					/>
					<label
						className={`${styles.selectAllSection_label} ${filteredDestinationList.length ? '' : styles.disabled}`}
						htmlFor='selectAllDestination'>
						Select all
					</label>
				</div>
			}
			<ul
				className='list_container'
				onDragOver={(event) => dragOverHandler(event)}
				onDrop={(event) => dropHandler(event)}
				style={destinationHeight ? { height: destinationHeight } : null}>
				<DraggableList<T1, T2, T3 | undefined, T4 | undefined>
					listData={destination}
					selectedList={selectedDestinationList}
					setSelectedList={setSelectedDestinationList}
					disableSelection={true}
					data={data}
					disabledTitle={'User cannot be removed as mapped to only this location'}
					disableDestinationItems={disableDestinationItems}
				/>
			</ul>
		</article>
	);
}

export default Destination;
