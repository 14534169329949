/* eslint-disable max-lines */
export const API_BASE_URL = process.env.REACT_APP_API_URL;
export const NO_INDEX = -1;
export const DEFAULT_USERS_PER_PAGE = 10;
export const DEFAULT_USERS_GROUP_AUDIT_PER_PAGE = 10;
export const USER_GROUPS_SHOW_LIMIT = 3;
export const USER_OFFICE_LOCATION_SHOW_LIMIT = 1;

export const CommonMessages = {
	UnAuthorized:
		'You do not have permission to perform this operation,\nplease contact your system administrator for more\ninformation.',
	NonPartner: 'This setting is not applicable for a non-partner user.',
	SignatureStampDisabled: 'ERO Signature stamps are disabled in firm settings.',
	BulkOperationDelay: 'This may take several minutes. Thank you for your patience.',
	NoResultsFound: 'No Results found',
};

export const LocalStorageConstants = {
	IsAdminKey: 'isAdmin',
	UserIdKey: 'userId',
};

export const UserManagementConstants = {
	SavingUserError: 'Saving user details failed, please re-login',
	UserUpdationSuccess: 'User details has been updated.',
	ImportInProgress:
		'User Management import in process. This will take time and an email will be sent to you upon completion. You can navigate away from this page.',
	ImportError: 'Failed to import user(s) data',
	UploadLinkError: 'Failed to generate upload link',
	DownloadImportUserTemplateError: 'Failed to download import user template',
	UserGroupMissing: 'Cannot save a User without any Groups',
	SuiteEROUserGroupAllocationDisabledMessage:
		'Inactive Suite - ERO Only users can only be edited on an individual level.',
	InactiveUserSelectionnMessage: "Choosing 'ERO Only' will deny user access to the software.",
};

export const LoadingScreenConstants = {
	LOADING: 'Loading...',
};

export const Version = {
	VersionNumber: 'V 1.0',
};

export enum profileMenuIndex {
	profile,
	loginHistory,
	logOut,
}

export const MyAccountTitle: Record<string, string> = {
	profile: 'Profile',
	password: 'Password',
};

export const ComposeValidations = {
	EMAIL_REQUIRED: 'Recipient email is required',
	EMAIL_DUPLICATE: 'Recipient already exists',
	INVALID_EMAIL_FORMAT: 'Email is invalid',
	QUESTION_REQUIRED: 'Question is required',
	ANSWER_REQUIRED: 'Answer is required',

	RECIPIENT_REQUIRED: 'Please add at least one recipient',
	WITHOUT_SUBJECT: 'Do you want to send this message without Subject?',
	WITHOUT_ATTACHMENT_WARNING: 'Do you want to send this message without attachment(s)?',
	ATTACHMENT_UPLOAD_INCOMPLETE_WARNING: 'Upload in progress. Please wait.',
	UNSAVED_OPTION: 'Message options are unsaved',
	RECIPIENT_QUESTIONANSWER_REQUIRED: 'Please fill out the fields in Message Options',
	MESSAGE_SENT_SUCCESSFULLY: 'Message Sent Successfully!',
	MESSAGE_SEND_FAILED: 'There was an error while sending the message. Please try again.',
	UPLOAD_IN_PROGRESS: 'Please wait until attachment finishes uploading',
};

export const FileConstants = {
	MaximumFileSize: '3221225472',
};

export const ValidationContants = {
	FirstNameWarning: 'Please enter first name.',
	LastNameWarning: 'Please enter last name.',
	NameLengthWarning: 'Name must have up to 100 characters',
	EmailAdrressWarning: 'Please enter email address.',
	ValidEmailAddressWarning: 'Please enter valid  email address.',
	ValidIdentityServerEmailAddressWarning:
		"Only letters (a-z), numbers (0-9), symbol (@), dash (-), underscore (_), period (.) and apostrophe (') are allowed.",
	EmailNotMatching: 'Email and Confirm Email did not match',
	PhoneNumberWarning: 'Please enter the phone number.',
	PhoneNumberLengthWarning: 'Please enter a 10 digit phone number.',
	MobileNumberWarning: 'Please enter the mobile number.',
	MobileNumberLengthWarning: 'Please enter a valid mobile number, according to the country code selected',
	CountryCodeWarning: 'Please select the country code.',
	PTINWarning: 'PTIN should start with P followed by 8 digit number.',
	EnterPasswordWarning: 'Please enter password and confirm password.',
	PasswordNotMatchedWarning: 'Password and confirmed password did not match.',
	NewPasswordWarning: 'Please enter new password.',
	OldPasswordWarning: 'Please enter old password',
	ConfirmPasswordWarning: 'Please enter confirm password',
	PasswordLengthWarning: 'Password must be 8 characters.',
	PasswordWithoutSpaceWarning: 'Please enter password without space.',
	ZipWarning: 'Please enter zip.',
	ZipLengthWarning: 'Please enter a 5 digit zip.',
	ExtensionWarning: 'Please enter less than 7 digit extension number.',
	FaxWarning: 'Please enter a 10 digit fax number.',
	StateWarning: 'Please select state.',
	CityWarning: 'Please enter city.',
	ValidCityWarning: 'Please enter valid city name.',
	PdfFileValidation: 'Please upload Pdf files only.',
	PaymentURLValidation: 'Please enter a valid payment URL.',
	FirmKeyWarning: 'Please enter valid firm key.',
	AzureGroupIdWarning: 'Please enter valid Azure Group Id.',
	PasswordWarning: 'Please enter password.',
	K1InstructionNotAVailable: 'K1 instruction is not available in the uploaded file',
	NoContentAvailable: 'No content available',
	EINWarning: 'Please enter valid EIN.',
	FileFormatNotSupported: 'Unsupported file type attached. We support PDF, DOC, Excel, PNG and JPEG file types.',
	FileSizeExceeded:
		'Sorry! The file(s) you are attaching exceed the allowable size limit (3GB). Please remove a few files and try again.',
	UpperCaseValidationError: 'Password should contain at least one uppercase character.',
	LowerCaseValidationError: 'Password should contain at least one lowercase character.',
	NumbersValidationError: 'Password should contain at least one number.',
	SpecialCharactersValidationError: 'Password should contain at least one special character.',
	CsvFileValidation: 'Please upload Csv files only.',
	SameEmailValidation: 'Email Already selected.',
	NotSelectedAnyMessageWarning: 'Please select any message.',
	EmptyInputFieldWarning: "This field can't be empty",
	DateInvalidWarning: 'Please enter valid date',
};

export const ProfileInformation = {
	TITLE: 'Profile Information',
	DESCRIPTION: 'View and manage your profile information here.',
	LABEL_TITLE: 'Title',
	LABEL_EMAIL: 'Email Address',
	LABEL_FIRST_NAME: 'First Name',
	LABEL_LAST_NAME: 'Last Name',
	LABEL_PHONE_NUMBER: 'Phone Number',
	LABEL_EXTENSION: 'Extension',
	LABEL_FAX_NUMBER: 'Fax Number',
	LABEL_PTIN: 'PTIN',
	LABEL_MOBILE_NUMBER: 'Mobile Number',
	PLACEHOLDER_TITLE: 'Enter Title',
	PLACEHOLDER_EMAIL: 'Enter Email Address',
	PLACEHOLDER_FIRST_NAME: 'Enter First Name',
	PLACEHOLDER_LAST_NAME: 'Enter Last Name',
	PLACEHOLDER_PHONE_NUMBER: 'Enter Phone Number',
	PLACEHOLDER_EXTENSION: 'Enter Phone Extension',
	PLACEHOLDER_FAX_NUMBER: 'Enter Fax Number',
	PLACEHOLDER_PTIN: 'Enter PTIN',
	PLACEHOLDER_MOBILE_NUMBER: 'Enter Mobile Number',
	VERIFY_TITLE: 'Verify Number',
	VERIFY_HELP_TEXT:
		'You can skip the verification step. On next login, you will be prompted to verify the mobile number',
	PLACEHOLDER_ACCESS_CODE: 'Access code',
	ACCESS_CODE_BUTTON: 'Verify Code',
	OK_BUTTON: 'Save',
	CANCEL_BUTTON: 'Cancel',
	FAX_LENGTH: 10,
	PTIN_LENGTH: 9,
	PHONE_NUMBER_LENGTH: 10,
	EXTENSION_LENGTH: 7,
	NAME_LENGTH: 100,
};

export const ChangePasswordConstants = {
	TITLE: 'Change Password',
	DESCRIPTION: 'Update your password for SafeSend login here.',
	LABEL_CURRENT_PASSWORD: 'Current Password',
	LABEL_NEW_PASSWORD: 'New Password',
	LABEL_CONFIRM_PASSWORD: 'Confirm Password',
	OK_BUTTON: 'Update Password',
	CANCEL_BUTOTN: 'Cancel',
	PASSWORD_POLICY_TITLE: 'Your password must have:',
	PASSWORD_LENGTH: 'Minimum Number of characters: ',
	PASSWORD_REQUIRED:
		'Required character types: Your password must include one of each of the following character types:',
	PASSWORD_SPECIAL_CHARACTERS: '(View list of available special characters)',
	PASSWORD_CHARACTERS_LIST: '~ ! @ # $ % ^ & * _',
	PLACEHOLDER_CURRENT_PASSWORD: 'Enter Current Password',
	PLACEHOLDER_NEW_PASSWORD: 'Enter New Password',
	PLACEHOLDER_CONFIRM_PASSWORD: 'Repeat New Password',
};

export const ChangeSecurityConstants = {
	SECURITY_CHANGE_MODAL_TITLE: 'Change Password',
	CONFIRM_BUTTON_NAME: 'Confirm',
	SECURITY_CHANGE_LOGOUT_WARNING: 'Changing your Password will require you to login again. Make the change?',
	SECURITY_UPDATE_SUCCESS: 'Password updated successfully.',
	SECURITY_UPDATE_FAILED: 'Updating password failed, please re-login',
};

export const LoginHistoryConstants = {
	TITLE: 'Login History',
	PROMPT_TEXT: 'Enable last login prompt',
	PROMPT_INFO: 'If enabled, you will see the last login notification every time you login.',
	LAST_LOGIN_MODAL_TITLE: 'Last Login',
	LAST_LOGIN: 'Last Login',
	LAST_LOGIN_PROMPT_TEXT: "Don't show this again",
	LAST_LOGIN_PROMPT_INFO: 'To turn back on, go to Login History',
	COUNT_PER_PAGE: 15,
};

export const DateFormatConstants = {
	DEFAULT_DATE_FORMAT: 'MM/DD/YYYY',
	REPORTS_DATE_FORMAT: 'MM/DD/YYYY hh:mm:ss a',
	LOGIN_TABLE_DATE_FORMAT: 'MM/DD/YYYY hh:mm:ss a',
	LAST_LOGIN_DATE_FORMAT: 'MM/DD/YYYY hh:mm:ss A',
	SAME_DAY_TIME_FORMAT: 'HH:mm',
	CURRENT_WEEK_DATE_FORMAT: 'ddd MM:DD',
	MESSAGE_DETAIL_DATE_FORMAT: 'ddd MM-DD-YYYY HH:mm',
	DEFAULT_TIME_FORMAT: 'HH:mm:ss A',
};

export const MyAccountConstants = {
	// API response
	APIResponse: {
		UserUpdateSuccess: 'User updated successfully.',
	},

	UserUpdateFailedMessage: 'Updating user details failed, please re-login.',
	SystemAdminDeletionErrorMessage: 'Cannot save a Primary Admin without SystemAdmin Group.',
	EROOnlyGroupErrorMessage: "Please check 'ERO only' checkbox",
};

export const OTPVerify = {
	InvalidOTP: 'Invalid access code',
	validOTP: 'Access code verified successfully',
};

export const userSettingsConstant = {
	APPLY_CHANGES_SUCCESS: 'Your settings have been updated.',
};

export const CompanySettingsConstants = {
	OverlayMessage: {
		ApplicationLoading: 'Loading, please wait...',
	},
	StatusMessage: {
		CompanySettingsError: 'Error occured while fetching company settings',
		CompanyDetailsError: 'Error occured while fetching company details',
		CompanyProfileError: 'Error occured while fetching profile details',
	},
	APPLY_CHANGES_SUCCESS: 'Admin settings have been updated.',
	Validate: {
		CompanyNameWarning: 'Please enter company name.',
		AddressWarning: 'Please enter address line 1.',
		StateWarning: 'Please select state.',
		WebsiteWarning: 'Please enter valid website.',
	},
};

export const PaymentHistoryConstants = {
	StatusMessage: {
		FetchPaymentHistoryError: 'Error occured while fetching Payment History.',
		ExportSuccess: 'Payment history exported successfully.',
		ExportError: 'Error occured while exporting Payment history.',
	},
	Validate: {
		FromDateEmptyWarning: 'From Date cannot be empty.',
		ToDateEmptyWarning: 'To Date cannot be empty.',
		ToDateLessThanFromDateWarning: 'To Date cannot be less than From Date.',
		NoPaymentHistory: 'No payment history is available for the selected date range.',
		NoPaymentDataToExport: 'No payment data available to export.',
	},
};

export const PurchaseReturnConstants = {
	PurchaseEssentialsReturnLimit: "You can't purchase more than 50,000 returns in an order. Please adjust the quantity.",
	PurchasePreferredReturnLimit: "You can't purchase more than 40,000 returns in an order. Please adjust the quantity.",
	PurchasePremiumReturnLimit: "You can't purchase more than 35,000 returns in an order. Please adjust the quantity.",
	FailurefetchPurchseSummary: 'Error occured while loading purchase details.',
	FailurefetchSaleTaxDetails: 'Error occured while fetching Sales Tax details.',
	SavePurchase: 'Purchase saved successfully.',
	FailureSavePurchase: 'There was an error while processing your purchase. Please try again later.',
	FailureGenerateToken: 'There was an error while adding the card. Please try again later.',
	Validation: {
		NoItemInCartValidation: 'Please add items to purchase.',
		SafeSendReturnCountValidation: 'Please enter valid SafeSend return quantity to purchase.',
		SignatureCountValidation: 'Please enter valid Signature quantity to purchase.',
		OrganizerCountValidation: 'Please enter valid SafeSend Organizer quantity to purchase.',
	},
};

export const IPAddressConstant = {
	FromIPWarning: 'Please enter from IP address.',
	ToIPWarning: 'Please enter to IP address.',
	ValidFromIPWarning: 'Please enter a valid from IP address.',
	ValidToIPWarning: 'Please enter a valid to IP address.',
	InvalidIPRangeWarning: 'Invalid IP address range!',
	CloseEditingWarning: 'Close current editing.',
	DeleteIpAddress: 'Are you sure, you want to delete?',
	SaveSuccessfully: 'IP Address saved successfully.',
	UpdateSuccessfully: 'IP Address updated successfully.',
	DeleteSuccessfully: 'IP Address deleted successfully.',
	IPAddressExist: 'IP Address already exist.',
};

export const ProductDownloadsConstant = {
	SignatureFlowSettingsDownloadNote: 'Click to download the SS Mail Merge setup file.',
	SignatureFlowSettingsDownloadNoteError: 'Error downloading the SS Mail Merge setup file.',
	SignatureFlowSettingsDownloadDisableNote:
		'We are sorry, but this feature is currently not available for your use.\nPlease contact your System Administrator for more information.',
	ExchangeWebAddInDownloadNote: 'Click to download the Exchange Web Add-in setup file.',
	ExchangeWebAddInDownloadNoteError: 'Error downloading the Exchange Web Add-in setup file.',
	ExchangeWebAddInDownloadDisableNote:
		'We are sorry, but this feature is currently not available for your use.\nPlease contact your System Administrator for more information.',
	ProductSubscriptionError:
		'This product is not supported on your current subscription tier,\nplease contact your Customer Success Representative for access.',
	TicTieCalculateDownloadNoteError: 'Error downloading the TicTie Calculate Plug-In setup file.',
	TicTieCalculateDownloadNote: 'Click to download the TicTie Calculate Plug-In setup file.',
	TicTieCalculateDownloadDisableNote:
		'We are sorry, but this feature is currently not available for your use.\nPlease contact your System Administrator for more information.',
	TicTieCalculateDownloadWarning:
		'TicTie Calculate is for Windows only and requires Adobe Acrobat (it will not work with Acrobat Reader).',
};

export const AddOnFeaturesConstant = {
	AddOnSubscriptionError:
		'This addon is not supported on your current subscription tier,\nplease contact your Customer Success Representative for access.',
};

export const SMSNotificationsConstant = {
	FetchSMSNotificationError: 'Error occured while fetching SMS Text Notification settings.',
	UpdateSMSNotificationError: 'Error occured while updating SMS Text Notification settings.',
	UpdateSMSNotificationSuccess: 'SMS Text Notification settings updated successfully.',
};

export const ClientHubNotifications = {
	ADDCONTACTPERSONSUCCESS: 'Contact person for Client Portal has been added successfully.',
	EDITCONTACTPERSONSUCCESS: 'Contact person for Client Portal has been edited successfully.',
	UPDATECLIENTHUBSETTINGSUCCESS: 'Client Portal Experience settings updated successfully.',
	UPDATINGCLIENTHUBSETTING: 'Updating Client Portal Experience Settings...',
	UPDATINGCONTACTPERSON: 'Updating Contact Person Details...',
	ERROR_SUBDOMAIN_INVALID: 'URL must satisfy the naming criteria.',
	ERROR_SUBDOMAIN_EXISTS: 'This URL is already in use. Please try again.',
	ERROR_CHECK_SUBDOMAIN_EXISTS: 'Error while validating subdomain.',
	ERROR_UPDATING_SUBDOMAIN: 'Error while updating subdomain.',
};

export const PasswordPolicyConstants = {
	StatusMessage: {
		PPSettingsError: 'Error occured while fetching Account Passwords settings.',
		UpdatePPSettingsSuccess: 'Account Passwords settings updated successfully.',
		UpdatePPSettingsError: 'Error occured while updating Account Passwords settings.',
	},
	Validate: {
		NoChanges: 'There are no changes to save.',
		MinimumCharacter: 'Minimum numbers of characters should be greater than 8.',
		MinimumOption: 'Select at least two options in character type.',
	},
};

export const SSOSettingsConstants = {
	StatusMessage: {
		GetSSOSettingsError: 'Error occured while fetching Single Sign On settings.',
		UpdateSSOSettingsSuccess: 'Single Sign On settings updated successfully.',
		UpdateSSOSettingsError: 'Error occured while updating Single Sign On settings.',
		ADAzureExist: 'Group ID has already been added on a different Account. Please enter a different Group ID.',
	},
	Validate: {
		GroupIdWarning: 'Please add at least one Group Id.',
		GroupIdAlreadyAdded: 'Group ID has already been added to the list. Please enter a different Group ID.',
		TenantIdWarning: 'Please add at least one Tenant Id.',
		TenantIdAlreadyAdded: 'Tenant ID has already been added to the list. Please enter a different Tenant ID.',
		SettingWarning: 'Please select at least one setting.',
	},
};

export const UserSettingsConstants = {
	fetchUserSettings: 'Failed to fetch User Settings.',
	saveUserSettingsSuccess: 'User Settings saved successfully.',
	saveUserSettingsError: 'Failed to save User Settings.',
	saveSignatureDelegations: 'Failed to save delegates.',
	fetchUserNotificationSettingsError: 'Failed to fetch User Notification Settings.',
	saveUserNotificationSettingsError: 'Failed to save User Notification Settings.',
	saveUserNotificationSettingsSuccess: 'User Notification Settings saved successfully.',
};

export const MFASettingsConstants = {
	StatusMessage: {
		UpdateMFASettingsSuccess: 'Multi-factor Authentication settings updated successfully.',
		UpdateMFASettingsError: 'Error occured while updating Multi-factor Authentication settings.',
	},
	Validate: {
		TrustedDeviceExpiryDays: 'Please enter the days between 1 to 60.',
	},
};

export const TaxSoftwareSettingsConstants = {
	NoTaxSoftwareMessage: 'Please select atleast one Tax Software.',
	UpdateTaxSofwareSuccess: 'Company settings updated successfully.',
};

export const ApiAccessSettingConstants = {
	UpdateApiAccessSuccess: 'Api access setting updated successfully.',
	FetchApiAccessError: 'Error occured while fetching API access settings.',
	UpdateApiAccessError: 'Error occured while updating API Access settings.',
	APIAccessInfoText:
		'The API follows role-based access controls by default. Enabling the toggle will bypass these role-based access control checks for the API.',
	ApiAccessText: 'API Access',
};

export enum ProductType {
	None = 0,
	Returns = 1,
	SignaturesOneOff = 2,
	SignaturesMailMerge = 3,
	Organizers = 4,
	Extensions = 5,
	Exchange = 6,
	Gather = 7,
	TicTieCalculate = 8,
	// Not a product,client.
	SSSuitePortal = 1001,
}

export enum AddOnFeatureType {
	None = 0,
	InOfficeEsign = 1,
}

export const Products = {
	0: '',
	1: 'Safesend Returns',
	2: 'Safesend Signatures',
	3: 'Safesend Mail Merge',
	4: 'Safesend Organizers',
	5: 'Safesend Extensions',
	6: 'Safesend Exchange',
	7: 'Safesend Gather',
	8: 'Safesend TicTie Calculate',
	1001: 'Safesend Suite',
};

export enum EngagementType {
	None = 0,
	E1040,
	E1065,
	E1120,
	E1120S,
	E1041,
	E1040NR,
	E990,
	E990PF,
	E990EZ,
}

export const HeaderConstants = {
	MY_ACCOUNT: 'Profile',
	MY_SETTINGS: 'My Settings',
	DROP_OFF_LINKS: 'Drop Off Links',
	MY_DOWNLOADS: 'My Downloads',
	LOGIN_HISTORY: 'Login History',
	SS_SUITE: 'SafeSend Suite',
	ACCOUNT_MANAGEMENT: 'Account Management',
	HELP: 'Help Centre',
	TOOLS: 'Tools',
};

export const MenuHeaderConstants = {
	ACCOUNT_MANAGEMENT: 'ACCOUNT MANAGEMENT',
};

export const LogoutConfirmationConstants = {
	TITLE: 'Log Out',
	CONFIRMATION_TEXT: 'Are you sure you want to log out? You will be logged out of all instances of the SafeSend Suite.',
	OK_TEXT: 'Yes, Log Out',
	CANCEL_TEXT: 'No, Discard',
};

export const UserAutoLogoutPopupConstants = {
	Header: 'Permission Changed',
	Message: 'Your SafeSend permissions have been changed. You must login again for the changes to take effect.',
};

export const CustomAuthorityConstants = {
	RESULTS_PER_PAGE: 10,
	StatusMessage: {
		CustomAuthoritiesError: 'Error occured while fetching custom authorities.',
	},
	cancelText: 'No, Keep It',
	headerText: 'Delete Custom Authority',
	bodyText: 'Are you sure you wish to delete this Custom Authority?',
	okText: 'Yes, Delete It',
	disableBtnText: 'Cannot delete custom authority.',
	inUseWarning: "The authority is in use so can't be deleted.",
	deleteAuthoritySuccess: 'Authority deleted successfully.',
	activeOrDeactiveSuccessMessage: 'Status changed successfully.',
	addCustomAuthoritySuccessMessage: 'Authority added successfully.',
	editCustomAuthoritySuccessMessage: 'Authority updated successfully.',
	duplicateAuthorityWarningMessage: 'Duplicate authority and state.',
	activeOrDeactiveErrorMessage: 'Error while updating status.',
	addOrUpdateErrorMessage: 'Error while adding new custom authority.',
	fetchAllCustomAuthorityErrorMessage: 'Error while fetching all custom authority details.',
	authorityRelatedTaxDocumentsErrorMessage: 'Error while fetching authority related tax documents details.',
	deleteAuthorityErrorMessage: 'Error while deleting custom authority.',
};

export const UserDeleteConfirmationPopupConstants = {
	cancelText: 'No, Keep User',
	headerText: 'Delete User?',
	bodyText: 'Are you sure you want to delete this user? You will permanently lose',
	okText: 'Yes, Delete User',
	disableBtnText: 'Cannot delete User.',
};

export const IPDeleteConfirmationPopupConstants = {
	cancelText: 'No, Keep It',
	headerText: 'Delete Address',
	bodyText: 'Are you sure you want to delete the IP Address?',
	okText: 'Yes, Delete It',
	disableBtnText: 'Cannot delete IP.',
};

export const ClientDeleteConfirmationPopupConstants = {
	cancelText: 'No, Keep Client',
	headerText: 'Delete Client?',
	bodyText: 'Are you sure you want to delete this client? This will permanently remove the client with ID',
	okText: 'Yes, Delete Client',
	disableBtnText: 'Cannot delete Client.',
};

export const ClientBulkDeleteConfirmationPopupConstants = {
	cancelText: 'No, Keep',
	headerText: 'Delete Client Records?',
	bodyText:
		'Are you sure you would like to permanently delete all of the selected clients? This action cannot be undone.',
	okText: 'Yes, Delete',
	disableBtnText: 'Cannot delete Clients.',
};
export const ClientBulkEditConfirmationPopupConstants = {
	cancelText: 'Cancel',
	headerText: 'Edit Office Location',
	bodyText: 'Are you sure you would like to change the office location for all selected clients? ',
	okText: 'Yes, Change Them',
};
export const UserDeleteAlterPopupConstants = {
	cancelText: 'Close',
	headerText: 'Action Not Allowed',
	loggedInUserDeleteMessage: 'The user is currently logged in and cannot be deleted.',
	primayAdminUserDeleteMessage:
		'This user is the primary admin for the account and cannot be deleted. You must set a new primary admin to delete this user.',
};

export const UserGroupAuditModalConstants = {
	headerText: 'Audit Report',
	tableHeaderGroupName: 'Group Name',
	tableHeaderChange: 'Change',
	tableHeaderChangedBy: 'Changed By',
	tableHeaderTimeStamp: 'Time Stamp',
};

export const UserErrorPopupConstants = {
	cancelText: 'Close',
	headerText: 'Action Failed',
	deleteUserError: 'Deletion of user failed due to time out.',
	deleteUserCustomError: 'Deletion of user failed.\nplease contact your system administrator for more\ninformation.',
};

export const InOfficeSigningConfirmationPopupConstants = {
	cancelText: 'No',
	headerText: 'Client Confirmation',
	primaryBodyText: 'Are you sure you would like to proceed with this client,',
	secondaryBodyText: ', for e-Signing?',
	okText: 'Yes',
};

export const DefaultContactPersonModalConstants = {
	headerText: 'Impacted Users',
	warningMessage:
		'You are trying to delete a user that has been selected as the <strong> Contact Person </strong>for returns that have already been delivered (i.e. the person the taxpayer should contact if they have questions).  Please select the contact person you would like to replace the deleted user.',
	saveBtn: 'Save',
	closeBtn: 'Close',
	tableHeaderFirstName: 'First Name',
	tableHeaderLastName: 'Last Name',
	tableHeaderEmailAddress: 'Email Address',
	lblContactPerson: 'Change Contact Person:',
};

export const RouteConstants = {
	ACCESSDENIED_MESSAGE_SCREENPERMISION:
		'You do not have permissions to access this page, please contact your system administrator for more information.',
	USER_MANAGEMENT: '/user-management',
	CLIENT_MANAGEMENT: '/client-management',
	DEVELOPER_SECTION: '/developer-section',
	UNAUTHORIZED: '/unauthorized',
	COMPANY_MANAGEMENT: '/company-management',
	BRANDING_MANAGEMENT: '/branding-management',
	USER_PERMISSION: '/user-permission',
	PAYMENT_HISTORY: '/payment-history',
	USAGE_REPORT: '/usage-report',
	GENERAL_SETTING: '/general-settings',
	SECURITY_SETTING: '/security-settings',
	GRANT: '/SSOAccessRequest/Grant',
	DENY: '/SSOAccessRequest/Deny',
};

export const EventSettingsConstants = {
	Actions: 'Actions',
	Active: 'Active',
	Add: 'Add',
	ApiEndpointBlank: 'API Endpoint cannot be blank',
	ApiEndpointInvalid: 'Please enter a valid API Endpoint URL',
	ApiEndPointLabel: 'API End Point',
	ApiEndPointPlaceholder: 'API End Point',
	ApiEndPointWarning: 'Enter API end point',
	ApiKeyBlank: 'API key cannot be blank',
	ApiKeyLabel: 'API Key',
	ApiKeyPlaceholder: 'API Key',
	ApiKeyWarning: 'Enter the API key',
	Close: 'Cancel',
	ConfigureEmail: 'Configure Email',
	ConfigureEndPoint: 'Configure API',
	Disabled: 'Disabled',
	DownloadDocumentation: 'Download Event API Documentation',
	EmailAlreadyExist: 'Email already exists',
	EmailLabel: 'Email(s)',
	EmailPlaceholder: 'Notify recipients in case of failures',
	EmailWarning: 'Please enter the email id',
	Enabled: 'Enabled',
	EnabledLabel: 'Enabled',
	EventAPINotFoundMessage: 'Please configure API Endpoint',
	EventLabel: 'Event',
	EventListLabel: 'Event List',
	EventsLabel: 'Events',
	InvalidEmailWarning: 'Enter a valid email',
	Resume: 'Resume',
	ResumeService: 'Resume Service',
	Save: 'Save',
	ServiceRunning: 'Service Running',
	ServiceStatus: 'Service Status',
	Stopped: 'Stopped',
	TestConnection: 'Test Connection',
	TestConnectionLabel: 'Test Connection',
	TestEventAPIFailed: 'Test API Endpoint connection failed/test again.',
	TestEventAPIInProgress: 'Testing connection..',
	TestEventAPISuccess: 'Test API Endpoint connection successful',
	TestEventAPISuccessStatus: 'Test API Endpoint connection successful with HttpStatus : ',
	UniqueArgumentLabel: 'Unique Arguments',
	UniqueArgumentPlaceholder: 'Optional',
	UserSignedEventResumeLabel: 'Resume',
	UpdateEventSettingsSuccess: 'Event settings were updated successfully.',
	Subscribe: 'Subscribe',
	Regenerate: 'Regenerate',
	PrimaryKey: 'Primary Key',
	SecondaryKey: 'Secondary Key',
	CopyPrimaryKey: 'Click to copy primary key',
	CopySecondaryKey: 'Click to copy secondary key',
	RegeneratePrimaryKey: 'Regenerate primary key',
	RegenerateSecondaryKey: 'Regenerate secondary key',
	PrimaryKeyRegenerated: 'Regeneration of the primary key is successful',
	SecondaryKeyRegenerated: 'Regeneration of the secondary key is successful',
	Subscribed: "Product's subscription has been successfully created.",
	SubscriptionKey: 'Subscription Key',
	PrimaryKeyCopied: 'Primary key copied',
	SecondaryKeyCopied: 'Secondary key copied',
	Log: 'Log',
};

export const UsageReportConstants = {
	noDataToPrint: 'No Usage data available to print.',
	noProductType: 'Please select a Product Type',
	noReturnType: 'Please select a Return Type',
	noStartDate: 'From Date cannot be empty.',
	noEndDate: 'To Date cannot be empty.',
	endDateLessThanStartDate: 'To Date cannot be less than From Date.',
};

export const SSOAccessRequestConstants = {
	StatusMessage: {
		SSOAccessRequestError: 'Error occured while fetching SSO Access Requests',
		GrantSSOAccessRequestSuccess: 'Access request to Safesend Suite via Office365 is granted successfully.',
		GrantSSOAccessRequestError: 'Failed to grant access request.',
		DenySSOAccessRequestSuccess: 'Access request to Safesend Suite via Office365 is Denied.',
		DenySSOAccessRequestError: 'Failed to deny access request.',
		RevokedSSOAccessRequestSuccess: 'Office 365 access revoked successfully.',
		RevokedSSOAccessRequestError: 'Failed to revoke Office 365 access.',
		AlreadyGranted: 'Access request has been already approved.',
		AlreadyDenied: 'Access request has been already rejected.',
	},
	UserPermission: 'User Permission',
	DenyConfirmation: 'Are you sure you want to deny this user?',
};

export const ClientManagementConstants = {
	emptyFile: 'The uploaded csv file is empty.',
	recordsExceeded: 'CSV over 5000 records can not be imported.',
	invalidCSVFile: 'Please upload a csv file only.',
	importInProgress:
		'Client Management import in process. This will take time and an email will be sent to you upon completion. You can navigate away from this page.',
};

export const OfficeLocationConstants = {
	addOfficeLocationSuccess: 'New office location added successfully.',
	addOfficeLocationError: 'Error occured while adding a new office location.',
	getUserIDsInLocationError: 'Error occured while fetching users in location.',
	updateOfficeLocationSuccess: 'Office location updated successfully.',
	updateOfficeLocationError: 'Error occured while updating office location.',
	getDataPresentInLocationError: 'Error occured while fetching data present in location.',
	getOfficeLocationDropdownDataError: 'Error occured while fetching list of office locations.',
	deleteModalCancelText: 'Cancel',
	deleteModalOkText: 'Yes, Delete It',
	deleteModalBodyText: 'Are you sure you want to delete this location permanently?',
	deleteModalHeaderText: 'Delete Office Location',
	deleteOfficeLocationSuccess: 'Office location successfully deleted.',
	deleteOfficeLocationError: 'Error occured while deleting office location.',
	disablePrimaryAdminDnD: 'This user is the Primary Admin for the account and cannot be removed',
	duplicateOfficeLocationName:
		'Another office location with the same name already exists. Please choose a different name for the new office location',
};

export const ApprovePasswordConstants = {
	approvePasswordError: 'Failed To Approve Password Request.',
};

export enum StateUSA {
	AL = 'Alabama',
	AK = 'Alaska',
	AZ = 'Arizona',
	AR = 'Arkansas',
	CA = 'California',
	CO = 'Colorado',
	CT = 'Connecticut',
	DC = 'District of Columbia',
	DE = 'Delaware',
	FL = 'Florida',
	Fed = 'Federal',
	GA = 'Georgia',
	HI = 'Hawaii',
	ID = 'Idaho',
	IL = 'Illinois',
	IN = 'Indiana',
	IA = 'Iowa',
	KS = 'Kansas',
	KY = 'Kentucky',
	LA = 'Louisiana',
	ME = 'Maine',
	MD = 'Maryland',
	MA = 'Massachusetts',
	MI = 'Michigan',
	MN = 'Minnesota',
	MS = 'Mississippi',
	MO = 'Missouri',
	MT = 'Montana',
	NE = 'Nebraska',
	NV = 'Nevada',
	NH = 'New Hampshire',
	NJ = 'New Jersey',
	NM = 'New Mexico',
	NY = 'New York',
	NC = 'North Carolina',
	ND = 'North Dakota',
	OH = 'Ohio',
	OK = 'Oklahoma',
	OR = 'Oregon',
	PA = 'Pennsylvania',
	PR = 'Puerto Rico',
	RI = 'Rhode Island',
	SC = 'South Carolina',
	SD = 'South Dakota',
	TN = 'Tennessee',
	TX = 'Texas',
	UT = 'Utah',
	VT = 'Vermont',
	VA = 'Virginia',
	VI = 'Virgin Islands',
	WA = 'Washington',
	WV = 'West Virginia',
	WI = 'Wisconsin',
	WY = 'Wyoming',
}

export const ClientManagementSettingsConstants = {
	UpdateClientManagementSettingsSuccess: 'Client Management settings updated successfully.',
	ClientManagementSettingMessage: 'Updating Client Management Settings...',
	AddMessage: 'Automatically includes new client records in the system without manual approval.',
	IgnoreMessage: 'Excludes new client records that are not already in the system, preventing automatic additions.',
	ConfirmMessage: 'Prompts user confirmation before adding new client records, ensuring manual approval.',
	Add: 'add',
	Ignore: 'ignore',
	Confirm: 'confirm',
};

export const KNOWN_EXCEPTION_MESSAGES = [
	'ResizeObserver loop limit exceeded',
	'ResizeObserver loop completed with undelivered notifications.',
	'ErrorEvent:',
	'ErrorEvent: Script error.',
];

export const monthAbbreviations = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
