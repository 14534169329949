import React, { useState } from 'react';
import './InOfficeReturnScreen.style.scss';
import SearchIcon from '../../../components/svg/SearchIcon';
import ConfirmationPopup from '../../../components/common/confirmationPopup';
import { InOfficeSigningConfirmationPopupConstants } from '../../../helper/Constants';
import { IInOfficeEsignReturn, IInOfficeReturnModel } from '../InOfficeESigning.model';
import InOfficeReturnTable from './inOfficeReturnTable';
import InOfficeReturnPagination from './inOfficeReturnPagination';
import SearchClearIcon from '../../../components/svg/SearchClearIcon';
import RefreshIcon from '../../../components/svg/RefreshIcon';
import { InOfficeScreen } from '..';
import { checkReturnStatus } from '../InOfficeESigning.api';
import { WithLogout, WithLogoutProps } from 'src/oidcClient/WithLogout';

interface InOfficeReturnScreenProps extends WithLogoutProps {
	inputText: string;
	inOfficeReturnList: IInOfficeEsignReturn;
	handleClearSearchOnClick: () => void;
	page: { pageIndex: number; pageSize: number };
	handleInputTextChange: (text: string) => void;
	handleOnRefreshClick: () => void;
	goToPage: (newPageIndex: number, newPageSize: number) => void;
	handleOnSearchClick: (text: string, screenId: InOfficeScreen) => void;
	redirectToTaxpayerSigningFlow: (selectedReturn: null | IInOfficeReturnModel, callback?: () => void) => void;
}

const InOfficeReturnScreen: React.FC<InOfficeReturnScreenProps> = (props) => {
	const {
		inputText,
		handleInputTextChange,
		inOfficeReturnList: { inOfficeReturnList, count, isLoading },
		handleOnSearchClick,
		page,
		handleClearSearchOnClick,
		goToPage,
		handleOnRefreshClick,
		onLogout
	} = props;

    const [selectedSigningReturn, setSelectedSigningReturn] = useState<null | IInOfficeReturnModel>(null);
    const [showGetStartedConfirmationPopup, setShowGetStartedConfirmationPopup] = useState(false);

    const handleGetStartedButtonClick = (returnItem: IInOfficeReturnModel) => {
        setSelectedSigningReturn(returnItem);
        checkReturnStatus(returnItem, () => {
            setShowGetStartedConfirmationPopup(true);
        });
    };

    const clearSelectedInOfficeSigningReturn = () => {
        setShowGetStartedConfirmationPopup(false);
    };

	const redirectToTaxpayer = () => {
		onLogout();
		props.redirectToTaxpayerSigningFlow(selectedSigningReturn, clearSelectedInOfficeSigningReturn);
	};

    const handleSubmitForm = (e: any) => {
        e.preventDefault();
    };

    return (
        <div className='inOfficeReturn'>
            <div className='inOfficeReturn_heading'>Client Search</div>
            <div className='inOfficeReturn_infoText'>
                The following clients matched your search - select “Get started” to start the e-Signing process
            </div>

            <div className='inOfficeReturn_formSection'>
                <form
                    onSubmit={handleSubmitForm}
                    className={'searchClient'}>
                    <input
                        type='text'
                        id='searchText'
                        placeholder='Search'
                        value={inputText}
                        maxLength={500}
                        onChange={(e: any) => handleInputTextChange(e.target.value)}
                        aria-label='Search for Client ID or Client Name'
                    />
                    {inputText && (
                        <SearchClearIcon
                            className='crossIcon'
                            onClick={() => {
                                handleInputTextChange('');
                                handleClearSearchOnClick();
                            }}
                            tabIndex={0}
                        />
                    )}
                    <button
                        style={{ cursor: `${inputText.trim().length ? 'pointer' : 'not-allowed'}` }}
                        className={'searchIcon'}
                        disabled={!inputText.trim()}
                        onClick={() => handleOnSearchClick(inputText, InOfficeScreen.ReturnScreen)}
                        aria-label='Search'>
                        <SearchIcon />
                    </button>
                </form>
                <RefreshIcon
                    className='refreshIcon'
                    onClick={handleOnRefreshClick}
                    tabIndex={0}
                />
            </div>

            <InOfficeReturnTable
                inOfficeReturnList={inOfficeReturnList}
                isLoading={isLoading}
                handleGetStartedButtonClick={handleGetStartedButtonClick}
                clearSelectedInOfficeSigningReturn={clearSelectedInOfficeSigningReturn}
            />

            <InOfficeReturnPagination
                count={count}
                page={page}
                goToPage={goToPage}
            />

            {showGetStartedConfirmationPopup && (
                <ConfirmationPopup
                    show={showGetStartedConfirmationPopup}
                    cancelButtonType={'danger'}
                    className={'inOfficeSigningConfirmationPopup d-flex justify-content-center align-items-center'}
                    cancelText={InOfficeSigningConfirmationPopupConstants.cancelText}
                    header={InOfficeSigningConfirmationPopupConstants.headerText}
                    message={
                        <>
                            <div>{InOfficeSigningConfirmationPopupConstants.primaryBodyText} </div>
                            <div>
                                <strong>{selectedSigningReturn?.clientId + ' - ' + selectedSigningReturn?.name}</strong>
                                {InOfficeSigningConfirmationPopupConstants.secondaryBodyText}
                            </div>
                        </>
                    }
                    onCancel={() => {
                        clearSelectedInOfficeSigningReturn();
                    }}
                    okText={InOfficeSigningConfirmationPopupConstants.okText}
                    onHide={() => {
                        clearSelectedInOfficeSigningReturn();
                    }}
                    actionButtonType='success'
                    onOk={() => {
                        redirectToTaxpayer();
                    }}
                />
            )}
        </div>
    );
};

export default WithLogout(InOfficeReturnScreen);
